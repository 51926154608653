import { useState, useEffect, useContext, useMemo } from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import { SubscriptionContext } from "./SubscriptionContext";
import SubscriptionAccessesService from './SubscriptionAccessesService';
import Cs from "../../services/CommonService";
import CheckAccess from '../Roles/CheckAccess'; 

const useSubscriptionAccess = () => {
  const {state: { user: currentUser }, dispatch:userDispatch} = useContext(AuthContext);
  const {subscriptionState, subscriptionDispatch} = useContext(SubscriptionContext);
  const [loading, setLoading] = useState(true);
  
  const isDataLoaded = useMemo(()=>{
    return (currentUser?.current_subscription?.id 
      && (currentUser.current_locale !== subscriptionState.currentLocale 
      || currentUser.current_organization_id !== subscriptionState.organizationId 
      || Object.keys(subscriptionState.subscriptionAccesses).length === 0))
  }, [currentUser?.current_locale, currentUser?.current_organization_id, subscriptionState])

  useEffect(() => {
    setLoading(isDataLoaded);
  }, [])

  /*Cache data regardless of permission, no cache restriction based on permission.*/
  /*useEffect(()=>{
    if(!loading){
      setOfflineMode();
    }
  }, [loading])

  const setOfflineMode = async () =>{
    if(navigator.serviceWorker.controller){
      const offlineAccess = CheckAccess.checkOrgAccess(currentUser, 'offline', 'menu_accesses');
      return new Promise(function(resolve, reject) {
        const messageChannel = new MessageChannel();

        navigator.serviceWorker.controller.postMessage(
          (offlineAccess?'EnableOfflineDataCache':'DisableOfflineDataCache'),
          [messageChannel.port2]
        )
      })
    }
  }*/

  const getSubscriptionAccess = async () =>{
    if(currentUser?.id && isDataLoaded){
      setLoading(true);
      const {status, data} = await SubscriptionAccessesService.get(
        currentUser.current_subscription?.id, 
        {'locale':currentUser.current_locale}
      );
      if(status === 200){
        subscriptionDispatch({
          'type':'SET_SUBSCRIPTION_ACCESSES',
          'payload':{
            'currentLocale':currentUser.current_locale,
            'organizationId':currentUser.current_organization_id,
            'subscriptionAccesses':data
          }
        });
        setSubscription(data);
        setLoading(false);
      }else{
        setLoading(false);
      }
    }
  }

  const setSubscription = (sa) =>{
    //already user.current_subscription.menu_accesses has permission for menu, sharing, digital content
    //remove in future
    const subscriptionAccesses = {
      ...Cs.listToObj(sa.section_menu_nav_bar?.options, 'sid'),
      ...Cs.listToObj(sa.subs_sharing_options?.options, 'sid'),
      ...Cs.listToObj(sa.menu_digital_content?.options, 'sid'),
      'form':Cs.listToObj(sa.form_field_config?.options, 'sid'),
      'training':Cs.listToObj(sa.training_menu?.options, 'sid'),
      'mcj_filter':Cs.listToObj(sa.mcj_filter_permission?.options, 'sid'),
    }
    
    currentUser.current_subscription.accesses = subscriptionAccesses;
    currentUser.current_subscription.journeys = sa.health_condition?.options;
    
    userDispatch({
      "type": "UPDATE",
      "payload": {'user': currentUser}
    })
  }

  const getUserRegForms = () => subscriptionState.subscriptionAccesses.public_user_reg_form?.options;

  return {
    ...subscriptionState,
    getSubscriptionAccess,
    loading,
    getUserRegForms
  }
};

export {useSubscriptionAccess};