import Cs from '../../services/CommonService';
import moment from '../../../node_modules/moment/min/moment.min.js';

let FormHelper = {};  
  
FormHelper.setInputTypeValidation = (field, inputAttributes, validationAttributes, position, formFn) => {
  	
    /*if(position == 0){
      inputAttributes.autoFocus = true;
    }*/

    const vo = field.field_validation_options;
  	if(vo){

		if(vo.validation_type){
			switch (vo.validation_type) {
    	  case 'email':
          field.component_type = "email";
    			inputAttributes['type'] = "email";
    			inputAttributes.pattern = "[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"; //Any domain
          //"^[a-z0-9](\.?[a-z0-9]){3,}@gmail.com$" //Gmail domain
          validationAttributes.patternMessage = "invalid email address";
        	break;
    		case 'number':
          inputAttributes['type'] = "number";
          inputAttributes['step'] = 'any';
          inputAttributes.inputMode = "numeric";
          if(vo.upper_limit != null){
            inputAttributes['max'] = vo.upper_limit;
          }

          if(vo.lower_limit != null){
            inputAttributes['min'] = vo.lower_limit;
          }
          
          if(vo.display_filter?.fraction_size !=null){
            inputAttributes['fraction_size'] = vo.display_filter.fraction_size;
          }
    				/*inputAttributes['pattern'] = vo.regex;
    				validationAttributes.pattern = {
            	value: vo.regex,
            	message: "invalid input pattern"
          	}*/
        	break;
        case 'url':
    			inputAttributes['type'] = "url";
          break;
        case 'alpha_numeric':
    			inputAttributes['pattern'] =  vo.regex; 
    			/*validationAttributes.pattern = {
        		value: vo.regex,
        		message: "invalid input"
        	}*/
        	break;
        case 'phone_no':
          field.component_type = "phone_no";
          inputAttributes.format = formFn.phone_display_format || vo.display_format; 
          inputAttributes.inputMode = "numeric";
    			//inputAttributes['pattern'] = vo.regex;
    			/*validationAttributes.pattern = {
          		value: vo.regex,
          		message: "invalid phone no"
          }*/
          break;
        case 'number_pattern':
          field.component_type = "number_pattern";
          inputAttributes.format = vo.display_format; 
          inputAttributes.inputMode = "numeric";
          //inputAttributes.isAllowed = ({ floatValue }) => floatValue >= 0 && floatValue <= 2460;
          break;
        case 'counter':
          inputAttributes['type'] = "number";
          break;
			  }
		  }
  	}
  }

  FormHelper.checkDecimalPoint = (value, fractionSize) => {
    if(fractionSize > 0 && value != null){
      return parseFloat(value).toFixed(fractionSize)
    }
    return value
  }

  FormHelper.setMinMaxValidation = (field, inputAttributes, validationAttributes) => {
  	const vo = field.field_validation_options;
  	if(vo){
  	  if(vo.min_length){
		    inputAttributes['minLength'] = vo.min_length;
	    }
	
	    if(vo.max_length){
		    inputAttributes['maxLength'] = vo.max_length;
	    }
  	}
  }

  FormHelper.checkInputValid = (field, ele, error) => {
    const vo = field.field_validation_options;
    if(vo && ele.value){
      if(vo.validation_type === 'email' && field.placeholder!=null && field.placeholder === ele.value){
        //Check placeholder
        ele.setCustomValidity('Invalid Email');
      }else if(vo.restrict_1st_chars && vo.restrict_1st_chars.includes(ele.value[0])){
        error.err_msg = vo.restrict_1st_chars + " not allowed as 1st char.";
        ele.setCustomValidity("Invalid");
      }else if(vo.restrict_all_chars){
        //pattern="[^*?<>|]+"
        const restrictChars = vo.restrict_all_chars.replace(/[ ,]/g, ""); //remove all spaces, commas. 
        const pattern = new RegExp("["+restrictChars+"]+");
        if (pattern.test(ele.value)){
          error.err_msg = vo.restrict_all_chars + " chars not allowed.";
          ele.setCustomValidity("Invalid");
        }else{
          ele.setCustomValidity('');
        }
      }else{
        ele.setCustomValidity('');
      }
    }
  }

  FormHelper.checkCustomValidation = (field, d, ds, error) => {
    /*const vo = field.field_validation_options;
    if(vo){
      error.warning_msg = false;
      const field_value =  FormHelper.getFieldEntry(field, d, ds);
      if((vo.lt_value && field_value < vo.lt_value) || (vo.gt_value && field_value > vo.gt_value)){
        error.warning_msg = true;
      }
    }*/
  }

  FormHelper.getFieldEntry = (field, d, ds) => {
    if(['radio', 'select'].indexOf(field.component)>-1){
       return ds[field.client_id].label
    }else if(field.component === 'autocomplete'){
       return d[field.client_id+'_label']
    }else{
       return d[field.client_id]
    }
  }

  FormHelper.checkAccessControl = (field, user) => {
    let access = true;
	  if(field.accesses && field.accesses.expression){
		  try{
        access = eval(field.accesses.expression);
      }catch(e){
        //console.log(e);
        access = false;
      }
  	}
    return access;
  }

  FormHelper.checkFieldConditionality = (field, modal_val, is_tab) => {
    if(field.hide_field) return false;
    let visibility = true;
    if(field?.visibility_logic){
      let e ='';
      const f = field.visibility_logic;
      if(f.exp_show && f.exp_show!=''){
        e = f.exp_show;
      }

      if(f.exp_hide && f.exp_hide!=''){
        if(e!=''){
          e += ' || '+f.exp_hide;
        }else{
          e = f.exp_hide;
        }
      }
      
      try{
        visibility = eval(e);
      }catch(e){
        //console.log(e);
        visibility = false;
      }
    }
    if(!visibility){
      is_tab ? 
        FormHelper.removeTabData(field.child_template_fields, modal_val)
        :
        FormHelper.removeFieldData(field, modal_val);
    }
    return visibility;
  }

  FormHelper.removeFieldData = (field, data) =>{
    if(['checkbox', 'multi_select', 'input_tags'].indexOf(field.component) > -1){
      delete data[field.client_id]
      delete data[field.client_id+'_array']
    }else{
      delete data[field.client_id]
    }
  }

  FormHelper.removeTabData = (childTemplateFields, formData) =>{
    for (let i = 0; i < childTemplateFields?.length; i++) {
      FormHelper.removeFieldData(childTemplateFields[i], formData)
    }
  }

  FormHelper.check_isNaN = (field) => {
	  if(!isNaN(field.client_id)){
		  field.client_id = 'field_'+field.client_id;
	  }
  }

  FormHelper.setDisabled = (field, inputAttributes) => {
  	if(field.read_only){
		  inputAttributes['disabled'] = true;
  	}	
  }

  FormHelper.initializeValue = (field, data, formMode) => {
	  if(
      field.default_value!=null
      && data[field.client_id]==null 
      && formMode == 'create-form-submissions' 
      && 'radio select checkbox'.includes(field.component)
    ){
		  data[field.client_id] = field.default_value;
	  }
  }

  FormHelper.setTouched = (e, form) => {
  	form[e.target.name].touched = true;
  }

  FormHelper.setDatePicker = (field, inputAttributes) => {
    const dmy = {"d":"day", "m":"month", "y":"year"};
    const vo = field.field_validation_options;
    field.dateFormat = "YYYY-MM-DD";
    if(vo){
      field.dateFormat = vo.display_format || field.dateFormat;
      if(vo.past_no && dmy[vo.past_type]){ 
        inputAttributes.minDate = moment().subtract(vo.past_no, dmy[vo.past_type]).toDate();
      }else if(vo.is_future_date?.indexOf('future') > -1){
        inputAttributes.minDate = new Date();
      }

      if(vo.future_no && dmy[vo.future_type]){
        inputAttributes.maxDate = moment().add(vo.future_no, dmy[vo.future_type]).toDate();
      }else if(vo.is_future_date?.indexOf('past') > -1){
        inputAttributes.maxDate = new Date();
      }
    }
  }

  /*
    @params d:data
    @params ds:data_source
  */
  FormHelper.onChangeExp = (field, d, ds) => {
    try{
      if(field.on_change_expression){
        const val = eval(field.on_change_expression);
        d[field.client_id] = FormHelper.checkDecimalPoint(
          val, field.field_validation_options?.display_filter?.fraction_size
        )
      }
    }catch(e){
      console.error(e.message)
    }
  }

  FormHelper.onChangeScript = (field, d, ds) => {
    try{
      if(field.on_change_script){
        eval(field.on_change_script);
      }
    }catch(e){
      console.error(e.message)
    }
  }

  FormHelper.isNumeric = (value) => {
    if(isNaN(value)){
      return value;
    }

    if(value.toString().indexOf('.') != -1){
      return parseFloat(value);
    }

    return parseInt(value);
  }

  FormHelper.checkFieldValidity = (field, data, errors, pkey) => {
    const name = field.client_id;
    if(field.required){
      FormHelper.setErrorFields(field, errors, pkey)
      /*
        component table_view wont be validated here. 
        because model name id dynamic based on row sid and col client id.
      */
      if(field.component === 'table_view'){
        const isInvalid = FormHelper.validateColumn(field, data)
        if(isInvalid)errors.invalid[name] = isInvalid;
        errors[name].invalid = isInvalid;
      }else if(data[name]==null || data[name]===''){
        errors.invalid[name] = true;
        errors[name].invalid = true;
      }
    }else if(['textInput'].indexOf(field.component)>-1 && !field.required){
      FormHelper.setErrorFields(field, errors, pkey)
    } 

    /*const notRequiredValidationField = (['textInput'].indexOf(field.component)>-1
    if(field.required || notRequiredValidationField){
      FormHelper.setErrorFields(field, errors, pkey)
      if(notRequiredValidationField && !field.required){
        errors.invalid[name] = false;
        errors[name].invalid = false;
      }elseif(data[name]==null || data[name]===''){
        errors.invalid[name] = true;
        errors[name].invalid = true;
      }
    }*/
  }

  FormHelper.setErrorFields = (field, errors, pkey) =>{
    const name = field.client_id;
    errors[name] = errors[name] || {};
    errors[name].required = field.required;
    errors[name].label = field.label;
    errors[name].id = field.client_id;
    errors[name].tab_index = pkey;
  }

  FormHelper.validateColumn = (field, data) =>{
    for (const col of (field.columns || [])) {
      if(col.required){
        for (const row of field.data_sources?.[field.row_data_source_id]?.options) {
          if(data[row.sid +'_'+ col.client_id]==null){
            return true
          }
        }
      }
    }
    return false
  }

  FormHelper.setErrorState = (errors, name, state) =>{
    errors[name].invalid = state;
    if(!state)
      errors[name].err_msg = null;
      delete errors.invalid[name];
  }
  
  FormHelper.resetFieldError = (errors, field) =>{
      delete errors[field.client_id];
      delete errors.invalid[field.client_id];
  } 

  FormHelper.setLabelStyle = (field, isFormWizard, parentAttributes) =>{
    let labelAttributes = {
      'id':'label_'+field.client_id
    }

    if(isFormWizard){
      labelAttributes.className=`regTitles2 m-b-15 bold-600 ${field.required && 'req-field'}`;
      parentAttributes.className="form-group col-xs-12";
    }else{
      labelAttributes.className=`form-label m-b-10 ${field.required && 'req-field'}`;
      parentAttributes.className=`br-blue-hov ${field.indent_left?'p-l-40 p-l-10-xss':''} ${field.no_of_column == '1'?'col-xs-12':'col-md-6'}`;
    }

    if(field.border_top){
      parentAttributes.className += ' br-top-dgrey';
    }

    if(field.border_bottom){
      parentAttributes.className += ' br-btm-dgrey';
    }

    return labelAttributes;
  }

  FormHelper.setFormTitle = function(component, data, value, dataSource){
    try{  
      if(component.is_form_title){
        if(component.component == 'multi_select' || component.component == 'checkbox'){
          const o = dataSource.options.reduce((obj, item) => (obj[item.value] = item, obj) ,{});
          let t = [];
          value.map((i)=>{
            t.unshift(o[i])
          })
          data.record_title = t.join(' ');
        }else if(dataSource){
          const o = dataSource.options.reduce((obj, item) => (obj[item.value] = item, obj) ,{});
          data.record_title = o[value].label;
        }else{
          data.record_title = value;  
        }
      }
    }catch(e){
        
    } 
  }

  FormHelper.validate = (formId, data, error, setError) =>{
    const ele = document.getElementById(formId)
    if(!ele)return;
    error = {is_valid:false}
    ele.querySelectorAll("[required]").forEach((i)=> {
      error[i.name] = error[i.name] || {}  
      if(Array.isArray(data[i.name]) && data[i.name].length == 0){
        error[i.name].invalid = true
      }else if((data[i.name] ?? false) !== false){
        delete error[i.name]
      }else{
        error[i.name].invalid = true
      } 

      /*if (!allAreFilled) return
        if (!i.value) allAreFilled = false
        if (i.type === "radio") {
          let radioValueCheck = false
          document.getElementById(formId).querySelectorAll(`[name=${i.name}]`).forEach((r) => {
            if (r.checked) radioValueCheck = true
          })
          allAreFilled = radioValueCheck
      }*/

    })
    error.is_valid = Object.keys(error).length == 1;
    setError({...error})
  }

  FormHelper.autoGenOptions = (autoGenOption={}, data) =>{
    const options = [];
    const start = autoGenOption.start!=null ? autoGenOption.start : data[autoGenOption.start_client_id];
    const end = autoGenOption.end!=null ? autoGenOption.end : data[autoGenOption.end_client_id];
    if(start!=null && end!=null && autoGenOption.steps!=null){
      for (let i = start; i <= end; i += autoGenOption.steps) {
        const num = parseFloat(i.toFixed(2)); 
        options.push({"label":num, "value":num});
      }
    }
    return options;
  }

  FormHelper.toggleListString = (item, list = [], setter) => {
    const idx = list.indexOf(item);
    if(idx > -1) {
      list = list.filter(i => i !== item)
    }else {
      list.push(item)
    }
    setter([...list])
  }

  FormHelper.existsListString = (item, list = []) => {
    return list?.indexOf(item) > -1
  }

  FormHelper.validateEmails = (mails) =>{
    const emails = mails.replace(/\s/g,'').split(",");
    let invalidEmails = []
    for (let i = 0; i < emails.length; i++) {
      if( emails[i] == "" || ! FormHelper.validateEmail(emails[i])){
        invalidEmails.push(emails[i])
      }
    }
    return invalidEmails
  }

  FormHelper.validateEmail = (email) =>{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  FormHelper.searchObjList = (list, term, type='start_with', searckKey='label') =>{
    const p = new RegExp((type==='start_with'?'^':'') + term, 'i');
    return list.filter(i => (p.test(i[searckKey]))); 
  }

  FormHelper.searchList = (list, term, type='start_with') =>{
    const p = new RegExp((type==='start_with'?'^':'') + term, 'i');
    return list.filter(i => (p.test(i))); 
  }

  FormHelper.confirmValue = (reconfirmValue=false, value, localeFn) =>{
    if(reconfirmValue && localeFn){
      const text = localeFn(7) +': '+ value +"\n\n"+ localeFn(5) +"\n\n"+ localeFn(6);
      if (window.confirm(text) == true) {
        return true;
      }
      return false;
    }

    return true
  }

  FormHelper.getValueFormKeys = (key, d, fd, ds) =>{
    try{
      if(key){
        return eval(key);
      }
    }catch(e){
      console.error(e.message)
    }
  }

export default FormHelper;