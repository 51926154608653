import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import querystringify from "querystringify"
import ParagraphQuestionService from '../ParagraphQuestionService'
import {OptionsObjList} from '../../../Common/FormInput'
import { useCurrentUserHook } from '../../../Authentication/useUserHook'

function ParagraphQuestionForm(props) {
  const {currentUser} = useCurrentUserHook()
  const [isFormRendered, setFormRendered] = useState(false)
  const [questions, setQuestions] = useState([])
  const [dataSources, setDataSources] = useState({})

  //let params = props.match.params
  //let queryParam = querystringify.parse(props.location.search)
  
  const get = () => {
    ParagraphQuestionService.getAll({
      'page':1,
      'per_page':100,
      'training_content_id':props.trainingContentId,
      'fields':['data_source']
    }).then((res) => {
      if(res.status==200){
        setQuestions(res.data.paragraph_questions)
        setDataSources(res.data.data_sources)
        setFormRendered(true)
      }
    })
  }
  
  useEffect(() => { 
    get()
  }, [])

  if(isFormRendered){
    return(
      <div className="p-l-15 p-r-15 p-t-10 p-b-10 border-dashed-sm">
        {questions.map((q, i) => <Field key={i} field={q} dataSource={dataSources[q.data_source_id]}/>)}
      </div>
    )
  }else{
    return null
  }
  
}

const Field = ({field, dataSource}) =>{
  return(
    <div className="flex aligncenter m-b-5">
      <span>{field.data.prefix}</span>
      {field.data_source_id ? 
        <select className="form-control input-1 input-sm w-auto">
          <OptionsObjList list={dataSource?.options || []} idKey="value" labelKey="label"/>
        </select>
        :
        <input type="text" className="form-control input-1 w-auto"/>
      }
      <span>{field.data.suffix}</span>
    </div>
  )
}

export default ParagraphQuestionForm;