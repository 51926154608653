import React, { Fragment, useState, useEffect, useContext, useRef} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"
import useStyle from '../../hooks/useStyle'
import ReactDOM from 'react-dom'
import Cs from '../../services/CommonService'
import env from "../../env"
import {Locales} from "../../constants/DataSourceList"
import CheckAccess from '../Roles/CheckAccess'
import DataSourceService from "../../services/DataSourceService"
import LocationService from "../../services/LocationService"
import LocaleService from "../../services/LocaleService"
import { useCurrentUserHook } from "../Authentication/useUserHook"

const LocaleList = (props) => {
  useStyle('dashboard');	
  const {currentUser, dispatch} = useCurrentUserHook()
  const localeEl = useRef(null);
  
  const {type, selectedItem, onTranslateComplete, onSelect, selectedItems} = props
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    if(['user_locale', 'role_locale'].includes(props.type)){
      getList()
    }
  }, [])

  let getList = () => {
    setLoading(true)
    let req = {
      data_source_params:{
        data_source_id: [3613],
      }
    }

    DataSourceService.getDataSource(req).then((res) => {
      const localeList = res[Object.keys(res)[0]].options
      if(props.type === 'user_locale'){
        setList(
          CheckAccess.filterUserLocale(localeList, currentUser)
        )
      }else{
        setList(localeList);
      }
      setLoading(false)
    })
  }

  const updateLocale = (l) => {
    setLoading(true)
    const req = {
      context: 'switch_locale',
      current_locale: l.sid
    }
    AuthenticationService.updateUserName(req).then((res) => {
      if (res.status == 200) {
        currentUser.current_locale = l.sid
        dispatch({
          type: "UPDATE",
          payload: { user: currentUser },
        })
        setLoading(false)
      } 
    })
  }

  /*useEffect(() => {
    document.addEventListener("click", handleClick, true)
    return () => {
      document.removeEventListener("click", handleClick, true)
    }
  }, [])

  const handleClick = (e) => {
    if (!localeEl.current.contains(e.target)){
       props.toggleModal(e)
    }
  }*/

  const onLocaleChange = (locale) => {
    setLoading(true)
    const translatesKeys = []
    const translatesValues = []
    for (const [key, value] of Object.entries(selectedItem.data)) {
      if(typeof value === 'string' && isNaN(value) && !selectedItem.data[key+'_array']){
        translatesKeys.push(key)
        translatesValues.push(value)
      } 
    }
    
    const req = {
      "source": "en",
      "q": translatesValues,
      "target": locale.iso_code
    }
    
    LocaleService.googleTranslate(req).then((res)=>{
      if(res.data){
        setLoading(false)
        res.data.translations.forEach((t, i)=>{
          selectedItem.data[translatesKeys[i]] = t.translatedText
        })
        selectedItem.data.language = locale.value
        selectedItem.data.language_sid = locale.sid
        onTranslateComplete(selectedItem)
      }else{
        onTranslateComplete(selectedItem)
        setLoading(false)
      }
    })
  }

  const UserLocaleMenu = () => list.map((o, i) => (
	  <Fragment key={i}>
  	  <div className="d-flex alignbaseline" onClick={()=>updateLocale(o)}>
  	    {selectedItems?.indexOf(o.sid) > -1 ?<span className="fas fa-check-circle"/>:''}
        <div className='m-l-10'>  
  	      <h5 className="d-title">{o.label}</h5>
        </div>
  	  </div>
  	  <div className="divider"/>           
  	</Fragment>
  )) 
  
  const LocaleMenu = () => Locales.map((o, i) => (
    <Fragment key={i}>
      <div className="d-flex" onClick={()=>onLocaleChange(o)}>
        <div className='m-l-10 d-flex alignbaseline'>  
          <h5 className="d-title">{o.label}</h5>
          {selectedItem?.data?.language === o.value?<span className="fas fa-check-circle m-l-15"/>:''}
        </div>
      </div>
      <div className="divider"/>           
    </Fragment>
  )) 

  const RoleLocaleMenu = () => list.map((o, i) => (
    <Fragment key={i}>
      <div className="d-flex alignbaseline" onClick={(e)=>{e.stopPropagation();onSelect(o)}}>
        {selectedItems.indexOf(o.sid) > -1 ?<span className="fas fa-check-circle"/>:''}
        <div className='m-l-10'>  
          <h5 className="d-title">{o.label}</h5>
        </div>
      </div>
      <div className="divider"/>           
    </Fragment>
  ))

  return(
    <div className="ml-container" ref={localeEl}>
      <div className="ml-section">
        <div className="content">
          {loading && <div className="spinner"/>}
          {props.type == 'user_locale' ? <UserLocaleMenu/> : 
            <>{props.type == 'role_locale' ? <RoleLocaleMenu/> : <LocaleMenu/>}</>
          }
          <small>3613</small>
        </div>
      </div>
    </div>
  )
}

export default LocaleList;