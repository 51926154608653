import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Cs from "../../services/CommonService";
import { Link } from "react-router-dom";
import {DraftBadge} from "../Common/NotificationPill";
import {UserName} from '../UserProfile/UserInfoPopup';
import {useSubscriptionAccess} from '../Subscriptions/useSubscription';
import {FormFieldCard, RenderData} from "../Common/FormFieldsView";
import UserRequestService from "./UserRequestService";
import FormTemplateService from "../../services/FormTemplateService";
import { useCurrentUserHook } from "../Authentication/useUserHook";

const UserRegReqBlock = (props) => {
  const {currentUser} = useCurrentUserHook()

  const [loading, setLoading] = useState(true);
  const [formFields, setFormFields] = useState([]);
  const [userReg, setUserReg] = useState({});

  let {journey, menuOptions, dataSource} = props;
  
  useEffect(()=>{
    getUserReq();
  }, [])

  let getUserReq = async () => { 
    const {status, data} = await UserRequestService.get(
      null, 
      {
        "organization_id": currentUser.current_organization_id,
        "email": currentUser.email,
        "phone_no": currentUser.phone
      }
    );
    setUserReg(data.request);
    getFormFields(data.request?.form_id);
  }

  let getFormFields = (formId) => { 
    if(formId){
      FormTemplateService.getFormFields({
        'ids':[formId], 
      }).then((res)=>{
        if(res.status === 200){
          setFormFields(res.data.form_fields[formId]);
          setLoading(false);
        }
      })
    }
  }

  if(!userReg) return null;

  return (
    <div className="p10">
      <div className="mxh-250 m-t-5 scroll-auto">
        <div className="table card-table p-5">
          <FormFieldCard formFields={formFields} 
            data={userReg.data} dataSource={userReg.data_source}/>
        </div>  
      </div> 
    </div>
  ) 
}

export default UserRegReqBlock;