import React, { useState, Fragment, useContext, useEffect, useMemo } from 'react';
import FilterSlider from "../../Common/FilterSlider";
import RoleAndUserFilter from '../../NotifiableRoles/RoleAndUserFilter';
import {CheckBox} from "../../Common/FormInput";
import useModal from '../../../hooks/useModal';
import SortDirection from '../../../components/Common/SortDirection';
import useSort from '../../../hooks/useSorting';
import RoleService from '../../Roles/RoleService';
import FacilityService from '../../Organizations/MedicalCareFacility/FacilityService';
import {FontAwesomeBtn} from "../../Common/Button";
import {FormFieldFilter, ActiveFormFieldFilters} from "../../Common/FormFieldFilter";
import FormHelper from '../../FormBuilder/FormHelpers';
import {NavIcon} from '../../Common/MenuItem';
import {useSearch} from '../../../hooks/useSearch';
import Cs from '../../../services/CommonService';
import {DateTimePicker, CTMultiSelect} from '../../Common/FormInput';
import {useMedicalFacilityAutofillHook} from '../../Organizations/MedicalCareFacility/useMedicalFacilityApi';
import {FilterAccordionPanel, FilterPill, useFilterHook} from "../../Common/AccordionPanel";
import { ClearFiltersBtn } from '../../Common/Button'
import {TrainingSessionsFilter} from "../../TrainingPrograms/TraineeTestCases/Filter";
import {CaseListFilter} from "../../../constants/DataSourceList";
import CheckAccess from '../../Roles/CheckAccess';
import {useCurrentUserHook} from "../../Authentication/useUserHook";
import {LocalizeContext} from "../../../contexts/Localize/LocalizeStateProvider";

const Filters = ({status, filter, setFilter, search, setSearch}) =>{
  const {localeState:{journeyStatus}} = useContext(LocalizeContext);
  const {currentUser, hasDepartments} = useCurrentUserHook();
  const {onSearchCallback, searchKeyword, clearSearch} = useSearch((s) => setSearch(s), search);
  const {onSearchCallback:onSearchFilter} = useSearch((value, name) => setFilter({...filter, [name]:value}));
  const {
    getFilters, getPhcList, facilities, setFacilities, dataSource, getDepartmentPkId
  } = useMedicalFacilityAutofillHook({
    organizationId: currentUser.current_organization_id,
    filter: filter,
    setLoading: ()=>{}
  });

  const {getCount} = useFilterHook({filter});

  const permission = useMemo(() =>{ 
    const {permissions} = CheckAccess.destructUser(currentUser);
    return permissions.mcj_filter;
  }, [currentUser.current_organization_id]);

  useEffect(() => {
    if(hasDepartments()){
     getFilters();
    }else{
      const org = currentUser.organizations.find(o => o.id === currentUser.current_organization_id);
      if(org.medical_facilities?.length > 0){
        setFacilities(org.medical_facilities);  
      }else{
        getPhcList();
      }
    }
  }, []);

   /*const [talukas, setTalukas] = useState([])

  useEffect(() => {
    getTalukas()
  }, [])

  const getTalukas = () =>{
    FacilityService.getLocations({
      'organization_id':organizationId,
      'fields':['taluka'],
      'is_distinct':true,
    }).then(({data, status})=>{
      setTalukas(data.list)  
    }) 
  }*/

  const toggleFilter = (value, name) => {
    if(value!=null && value != filter[name]){
      filter[name] = value;
    }else{
      delete filter[name];
    }
    if(name === 'department') {
      delete filter.medical_facility_ids;
      delete filter.department_dsl_id;
      if(permission?.facilities){
        setFacilities([]);
        filter.department_dsl_id = getDepartmentPkId(filter.department);
        getPhcList();
      }
    }
    setFilter({...filter});
  }

  const filterExists = (value, name) => {
    return filter[name] == value;
  }
  
  const toggleListFilter = (value, name) => {
    const setter = (list) => {
      if(list?.length === 0){
        delete filter[name];
        setFilter({...filter});
      }else{
        setFilter({...filter, [name]:list});
      }
    }
    FormHelper.toggleListString(value, filter[name], setter);
  }

  const filterListExists = (value, name) => FormHelper.existsListString(value, filter[name]);

  return (
    <>
      <div className="filter-box-scroll p-5">

        <div className="bg-white m-b-10">
          <FilterAccordionPanel
            title="Case status"
            count={getCount('is_active') + getCount('my_assignment') + getCount('is_draft') + getCount('status')}
            isOpen={(filter.is_active || filter.my_assignment || filter.is_draft || filter.status)}>
            <div className="mxh-250 scroll-auto m-t-10">
              <CheckBox list={CaseListFilter.is_active}
                labelKey="label" valueKey="value"
                toggle={toggleFilter} exists={filterExists}
                name="is_active" />
              <CheckBox list={CaseListFilter.assignment}
                labelKey="label" valueKey="value"
                toggle={toggleFilter} exists={filterExists}
                name="my_assignment" />
              <CheckBox list={CaseListFilter.draft} 
                labelKey="label" valueKey="value" 
                toggle={toggleFilter} exists={filterExists} 
                name="is_draft"/> 
              <CheckBox list={journeyStatus}
                labelKey="label" valueKey="sid"
                toggle={toggleFilter} exists={filterExists}
                name="status" />
            </div>
          </FilterAccordionPanel>
        </div>

        <div className="bg-white m-b-10">
          <FilterAccordionPanel
            title="Date"
            count={getCount('last_activity_start_date') + getCount('last_activity_end_date')}
            isOpen={(filter.last_activity_start_date || filter.last_activity_end_date)} />
          <DateTimePicker name="last_activity_start_date"
            className="form-control m-b-10"
            placeholder="Start Date"
            defaultValue={filter.last_activity_start_date}
            maxDate={new Date()}
            onChange={(name, date, dateObj) => toggleFilter(date, name)} />
          <DateTimePicker name="last_activity_end_date"
            className="form-control m-b-10"
            placeholder="End Date"
            defaultValue={filter.last_activity_end_date}
            minDate={Cs.stringToDate(filter.last_activity_start_date)}
            maxDate={new Date()}
            onChange={(name, date, dateObj) => toggleFilter(date, name)} />
        </div>

        <div className="bg-white m-b-10">
          <FilterAccordionPanel title="Search By" count={searchKeyword?.length > 0 ? 1 : null} isOpen={searchKeyword?.length}>
            <div className="flex aligncenter">
              <input className={"form-control m-b-5 f17"+(searchKeyword?.length > 0 ?' br-green2':'')} 
                placeholder="Search" onChange={e=>onSearchCallback(e)} 
                value={searchKeyword}/>
              <i className="fas fa-times search-close" onClick={()=>clearSearch()}/>
            </div>

            <CheckBox list={CaseListFilter.search_by} 
              labelKey="label" valueKey="sid" 
              toggle={toggleFilter} exists={filterExists} 
              name="search_by"/> 
          </FilterAccordionPanel>
        </div>

        <FormFieldFilter
          formId={currentUser.current_subscription?.journeys[0]?.child_form}
          filter={filter}
          setFilter={setFilter} />

        { permission?.trainee_batch ? (
            <TrainingSessionsFilter
              organizationId={currentUser.current_organization_id}
              toggleFilter={toggleFilter}
              filterExists={filterExists} 
              filter={filter}/>
          ) : null
        }

        <div className="bg-white m-b-10">
          <h5 className="font-16 text-center sub-title">
            No Activity Days
          </h5>

          <div className="flex aligncenter">
            <input onChange={e=> onSearchFilter(e)} 
              name="no_activity_days"
              value={filter.no_activity_days || ""}
              className="form-control m-b-5 f15"
              placeholder="No Activity N days"/>
            <i className="fas fa-times search-close" 
              onClick={() => toggleFilter(null, 'no_activity_days')}/>
          </div>
        </div>

        <div className="bg-white m-b-10">
          <FilterAccordionPanel
            title="Z-score (Weight)" 
            count={getCount('weight_zscore_label_in') + getCount('weight_zscore_trend')}
            isOpen={(filter.weight_zscore_label_in || filter.weight_zscore_trend)}>
            <div className="mxh-250 scroll-auto m-t-10">
              <CheckBox
                list={CaseListFilter.weight_zscore_label}
                labelKey="label"
                valueKey="label"
                toggle={toggleListFilter}
                exists={filterListExists}
                name="weight_zscore_label_in"/>

              <CheckBox
                list={CaseListFilter.faltering}
                labelKey="label"
                valueKey="value"
                toggle={toggleFilter}
                exists={filterExists}
                name="weight_zscore_trend"/>
            </div>
          </FilterAccordionPanel>
        </div>

        <div className="bg-white m-b-10">
          <FilterAccordionPanel
            title="Z-score (WFH)"
            count={getCount('wfh_zscore_label_in')}
            isOpen={filter.wfh_zscore_label_in}>
            <div className="mxh-250 scroll-auto m-t-10">
              <CheckBox list={CaseListFilter.wfh_zscore_label}
                labelKey="label" valueKey="label"
                toggle={toggleListFilter} exists={filterListExists}
                name="wfh_zscore_label_in" />
            </div>
          </FilterAccordionPanel>
        </div>

        {hasDepartments() && permission?.department ? (
          <div className="bg-white m-b-10">
            <FilterAccordionPanel
              title="Department"
              count={getCount('Department')}
              isOpen={filter.department}>
              <div className="mxh-250 scroll-auto m-t-10">
                <CheckBox list={dataSource.health_department_india?.options || []}
                  labelKey="label" valueKey="value"
                  toggle={toggleFilter} exists={filterExists} name="department" />
              </div>
            </FilterAccordionPanel>
          </div>
        ) : null
        }

        {(permission?.facilities && facilities?.length > 0) ?
          <div className="bg-white m-b-10">
            <FilterAccordionPanel
              title="Partner"
              count={getCount('medical_facility_ids')}
              isOpen={filter.medical_facility_ids}>
              <div className="mxh-250 scroll-auto m-t-10">
                <CheckBox list={facilities}
                  labelKey="name" valueKey="id"
                  toggle={toggleListFilter} exists={filterListExists}
                  name="medical_facility_ids" />
              </div>
            </FilterAccordionPanel>
          </div>
          :
          null
        }
        
        {permission?.case_reports ? (
            <div className="bg-white m-b-10">
              <FilterAccordionPanel
                title="Case Reports"
                count={getCount('case_report')}
                isOpen={filter.case_report}>
                <div className="mxh-250 scroll-auto m-t-10">
                  <CheckBox list={CaseListFilter.case_reports}
                    labelKey="label" valueKey="value"
                    toggle={toggleFilter} exists={filterExists}
                    name="case_report" />
                </div>
              </FilterAccordionPanel>
            </div>
          ) : null
        }

      </div>
      <div className="p-5">
        <ClearFiltersBtn
          onSelect={() => { setSearch(''); setFilter({}); }}>
          Clear all filters
        </ClearFiltersBtn>
      </div>
    </>
  )
}

const FilterBtn = (props) =>{
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();
  const filterCount = Cs.objCountNonNull(props.filter); 

  return(
    <>
      <NavIcon
        id="filter" 
        dataTip={props.dataTip} 
        onSelect={()=>toggleFilterModal()}
        className="bg-highlight round-btn float-right"
        icon="fas fa-sliders-h">
          {filterCount > 0 ? <span className="badge-2 pos-abs bg-black-light-1 up">{filterCount}</span>:''}
      </NavIcon>

      {isFilterOpen &&
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={toggleFilterModal}
          title= "Filters (Case List)"
          {...props}/>
      }
    </>
  )
}

const ActiveFilters = ({filter, setFilter}) => {
  const {currentUser} = useCurrentUserHook();
  const {getCount} = useFilterHook({filter});

  return (
    <div>
      <FilterPill filter={filter} setFilter={setFilter}
        label="Case status"
        count={getCount('is_active') + getCount('my_assignment') + getCount('is_draft') + getCount('status')}
        filterKeys={['is_active', 'my_assignment', 'is_draft', 'status']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Department"
        count={getCount('department')}
        filterKeys={['department']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Partner"
        count={getCount('medical_facility_ids')}
        filterKeys={['medical_facility_ids']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Case Reports"
        count={getCount('case_report')}
        filterKeys={['case_report']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Z-score (WFH)"
        count={getCount('wfh_zscore_label_in')}
        filterKeys={['wfh_zscore_label_in']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Z-score (Weight)"
        count={getCount('weight_zscore_label_in')}
        filterKeys={['weight_zscore_label_in']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Search By"
        count={getCount('search_by')}
        filterKeys={['search_by']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Trainee Program"
        count={getCount('training_session_id')}
        filterKeys={['training_session_id']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Date"
        count={getCount('last_activity_start_date') + getCount('last_activity_end_date')}
        filterKeys={['last_activity_start_date', 'last_activity_end_date']}
      />

      <ActiveFormFieldFilters
        formId={currentUser.current_subscription?.journeys[0]?.child_form} 
        filter={filter} setFilter={setFilter}/>
    </div>
  );
};


export {Filters, FilterBtn, ActiveFilters};