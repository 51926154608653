import React, { useState, Fragment, useEffect, useContext, useRef } from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import Child from "./Child";
import Pagination from "../../../../components/Common/Pagination";
import ConfirmMenu from "../../../../components/Modals/ConfirmMenu";
import ProjectMenu from "../../../../components/Modals/ProjectMenu";
import FormTemplateId from "../../../../constants/FormTemplateList";
import CommentsModal from "../../../../components/Modals/CommentsModal";
import ChecklistsModal from "../../../../components/Modals/ChecklistsModal"
import { Link } from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import useStyle from "../../../../hooks/useStyle";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import Title from "../../GenericPortalPage/Title";
import ChildService from "./ChildService";

// let requestParams = {};
// let selectedChild = {};
// let dataSource = {};
// let journeyCategory = null;

const ChildList = (props) => {
  useStyle("card");
  useStyle("card_table");

  let params = props.match.params;
  let {project, child} = props.location.state;
  let queryParam = querystringify.parse(props.location.search);

  let cardGridCss = "col-xs-12 col-sm-6 col-md-4";
  let cardHeightCss = "ht-400";
  if (queryParam.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-8";
    cardHeightCss = "mih-380";
  }

  const { state: authState, dispatch } = useContext(AuthContext);
  const [childList, setChildList] = useState([]);
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal();
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal();
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal,} = useModal();
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal();

  let {current:scope} = useRef({selectedChild:{}, requestParams:{}, dataSource:{}, journeyCategory:null});

  useEffect(() => {
    ReactTooltip.rebuild()
    scope.journeyCategory = null;
    scope.requestParams = {
      journey_profile_id: params.health_id,
      activity_form_id: FormTemplateId.HealthChildForm
    };

    getJourneyChildList();
  }, [props.searchString, currentpage]);

  let getJourneyChildList = () => {
    ChildService.getAll(scope.requestParams).then((res) => {
      if (res.status == 200) {
        scope.dataSource = res.data.dataSource_list;
        //requestParams.totalItems = res.data.meta.total;
        //journeyCategory = res.data.meta.journeyCategory;
        //CheckAccess.userAccess(authState.user, journeyCategory, res.data.meta.invited_journey_member);
        setChildList(res.data.activities);
      }
    });
  };

  const deleteActivity = (e) => {
    e.preventDefault();
    ChildService.delete(scope.selectedChild.id).then((res) => {
      if (res.status == 204) {
        setChildList(
          childList.filter((i) => i.id !== scope.selectedChild.id)
        );
      }
    });
    toggleDeleteModal(e);
  };

  const showMenu = (e, a) => {
    scope.selectedChild = a;
    toggleMenuModal(e);
  };

  const addChecklist = (e, a) => {
    scope.selectedChild = a;
    toggleChecklistModal(e);
  }

  const addNotes = (e, a) => {
    scope.selectedChild = a;
    toggleCommentModal(e);
  }

  const routeActivity = (activity) => {
    props.history.push(
      `/team/activity/${params.journeyCategory_id}/${params.education_journey_profile_id}?id=${activity.id}`
    );
    setChildList(
      childList.filter((i) => i.id == activity.id)
    );
  };

  const menuItems = ({ item }) => {
    let child_data = {
      child_fk_id: item.record_id, 
      dob_baby_formatted_date: item.data.dob_baby_formatted_date, 
      id_mother:item.data.mother_fk_id, 
      baby_age:item.data.baby_age
    }
    
    return (
      <Fragment>
        <Link to={`/infant/child/form/${item.journey_profile_id}/${item.id}`}>
          <i className="far fa-edit font-15" aria-hidden="true"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </Link>

        <a href="#" onClick={(e) => toggleDeleteModal(e)}>
          <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>

        <Link to={{pathname:`/infant/activity/form/${item.journey_profile_id}`,
          state: child_data,
          search: 'activity_form_id=482' 
        }}>
          <i className="far fa-edit font-15" aria-hidden="true"></i>
          <span className="font-13">Record Weekly Data</span>
          <i className="fa fa-angle-right"></i>
        </Link>

        <Link to={{pathname:`/infant/activity/form/${item.journey_profile_id}`,
          state: child_data,
          search: 'activity_form_id=483' 
        }}>
          <i className="far fa-edit font-15" aria-hidden="true"></i>
          <span className="font-13">Record CF Follow-up</span>
          <i className="fa fa-angle-right"></i>
        </Link>
      </Fragment>
    );
  };

  const Menu = ({ activity }) => (
    <i
      data-tip="Menu"
      onClick={(e) => {
        showMenu(e, activity);
      }}
      className="fas fa-bars m-r-15"
    />
  );

  /*if(childList.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  const journeyActivityCard = childList && childList.map((a, i) => (
    <div className={cardGridCss} key={i}>
      <Child activity={a} dataSource={scope.dataSource} menu={Menu}
      addNotes={addNotes} addChecklist={addChecklist}
      journeyCategory={scope.journeyCategory} cardHeightCss={cardHeightCss}
      routeActivity={routeActivity}/>
        {scope.selectedChild.id == a.id && (
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
            item={scope.selectedChild}  type="activity"  MenuItems={menuItems}/>
        )}
      </div>
  ))

  return (
    <Fragment>
      <div className="content m-t-10 clearfix">
        <div className="col-xs-8">
          <Title title={queryParam.label + " Info"} project={props.location.state} />
        </div>
        <div className="col-xs-4">
          <Link to={`/infant/portal/${params.health_id}`}
            data-tip="Back to Journey Home"
            className="bg-highlight round-btn float-right m-r-5 m-t-4">
            <i className="fas fa-home"></i>
          </Link>
        </div>
      </div>

      <div className="page-content row">
        {journeyActivityCard}
        <Pagination totalItems={scope.requestParams.totalItems} currentpage={currentpage}
        setCurrentpage={setCurrentpage} itemsPerPage={scope.requestParams.per_page}/>
      </div>

      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteActivity} />
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={scope.selectedChild} type="infant_journey.child" />}
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={scope.selectedChild} type="infant_journey.child"/>}
      </Fragment>
  );
};

export default ChildList;