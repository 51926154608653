import React, {useState, Fragment, useContext, useEffect} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import {AddLocale} from "../Locale/AddLocale"
import GenericModal from "../../components/Modals/GenericModal"
import useModal from "../../hooks/useModal"
import { useCurrentUserHook } from "../Authentication/useUserHook";

const EditLocaleIco = (props) =>{ 
	const {localeType, labelObj, onLocaleUpdate} = props
	const {currentUser} = useCurrentUserHook()
	const {isOpen: isLocaleModalOpen, toggleModal: toggleLocaleModalOpen} = useModal()

	const openLocaleModal = (e) =>{
		e.preventDefault()
		e.stopPropagation()
		toggleLocaleModalOpen(e)
	}

	const updateLocale = (locale, value) =>{
	  let d = labelObj
      d.locale_data = d.locale_data || {}
      d.locale_data[locale] = {label:value}
      onLocaleUpdate(d)
	}

	if(isLocaleModalOpen){
	  return(
		<GenericModal component={AddLocale}  localeType={localeType} 
	      title="Language Translator" toggleModal={toggleLocaleModalOpen} 
	      onCancel={toggleLocaleModalOpen} labelObj={labelObj}
	      updateFormLocale={updateLocale}/>
      )
    }

	if(currentUser.edit_locale){
	  return(
      	<div onClick={(e)=>toggleLocaleModalOpen(e)} className={`fas fa-edit m-r-10 ${props.class}`}/>
	  )
	}

	return null
    
}

export {EditLocaleIco}