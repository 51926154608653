import React, { useState, useEffect, Fragment, useContext, useRef} from 'react';
import {Link, useHistory} from "react-router-dom";
import OrganizationMemberService from "./OrganizationMemberService";
import Cs from "../../../services/CommonService";
import {Table, THead, TH, TR, TD, TBody} from "../../Common/TableView";
import {PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon} from "../../Common/ImageIcons";
import {RecordId, MCJDetails, MCJDetails_2, MobileRequired, BabyNameRequired, AddressRequired, StatuDropDown, Path} from "../../HealthJourney/List/Common";
import {usePageLocale} from '../../../hooks/useLocale';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import {NotificationPopup} from "../../Common/NotificationPopup";
import PaginationSM from '../../Common/PaginationSM';
import { useCurrentUserHook } from '../../Authentication/useUserHook';

const MemberJourneyList = ({params, member, pageId, isRouteEnabled=true, toggleJourneyMenuModal}) => {
  const history = useHistory()
  const {currentUser} = useCurrentUserHook()

  let {current:scope} = useRef({'requestParams':{}})

  const [loading, setLoading] = useState(true)
  const [journeyProfiles, setJourneyProfiles] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])

  const { labelLocale } = usePageLocale(currentUser.current_locale, 'journeyListPage')

  useEffect(() => {
    scope.requestParams = {
      'page':currentpage,
      'per_page':15,
      'sort_column':'updated_at',
      "sort_direction":'desc',
      'organization_id':params.organization_id,
      'medical_facility_id':params.medical_facility_id,
      'member_user_id':member.user_id,
      'member_role_id':member.role_record_id
    } 
    getMemberJourneys()
  }, [currentpage])

  const getMemberJourneys = () =>{
    OrganizationMemberService.getJourneyProfiles(scope.requestParams).then((res) => {
      if (res.status == 200) {
      	setJourneyProfiles(res.data.journey_profiles)
        setPagemeta(res.data.meta)
      	setLoading(false)
      }
    })
  }

  let routeJourney = (journey)=>{
    if(isRouteEnabled){
      Cs.routeJourney(journey, {history: history})
    }
  }

  const tableData = journeyProfiles.map((j, k) => {
    let data = j.data || {}

    return(
      <TR key={k} className="">
        <TD className="text-gray">
          {pageId === "OrgMemberJourneyList" ?
            <RecordId j={j}/>
            :
            j.record_id
          }
        </TD>
        <TD>
          <MCJDetails j={j} routeJourney={routeJourney} labelLocale={labelLocale}/>
          <MCJDetails_2 j={j}/>
          {pageId === "OrgMemberJourneyList" ? <Path j={j}/> : null}
        </TD>
        {pageId === "OrgMemberJourneyList" ?
          <>
            <TD className="vat text-cap">
              <span className="m-t-10">{j.status}</span>
            </TD>

            <TD className="vat m-t-10 d-flex">
              {j.status !== 'closed' && 
                <span id="path_details" data-tip="View path details" 
                  onClick={()=>routeJourney(j)} 
                  className="fa far fa-map font-18 text-muted"/>
              }
            </TD>
          </>
          :
          null
        }
      </TR>
    )
  })

  return(
    <div id="ct">  
  	  <div className="table-responsive vh-70 scroll-auto" >
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH>Case ID</TH>
              <TH>Name</TH>
              {pageId === "OrgMemberJourneyList" ?
                <>
                  <TH>Status</TH>
                  <TH>Options</TH>
                </>
                :
                null
              }
            </TR>
          </THead>
          <TBody>
            {tableData}
          </TBody>
        </Table>
      </div>

      <PaginationSM
        className="pagination-bar"
        currentPage={currentpage}
        totalItems={pagemeta.total}
        itemsPerPage={pagemeta.per_page}
        onPageChange={setCurrentpage}
        siblingCount={2}/>
          
      {(toggleJourneyMenuModal && journeyProfiles.length === 0) ? 
        <NotificationPopup title={labelLocale(16)}
          onSuccess={toggleJourneyMenuModal}  
          iconClass="fas fa-circle-info lred"/>
        :
        null
      }

    </div>
  )

}

export default MemberJourneyList;