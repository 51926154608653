import React, { Fragment, useState, useEffect, useContext } from 'react'
import useStyle from '../../hooks/useStyle'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {usePageLocale} from '../../hooks/useLocale'
import { useCurrentUserHook } from '../Authentication/useUserHook'

const CookieNotice = (props) => {
  useStyle('/css/cookie.css', true);

  const {currentUser} = useCurrentUserHook()
  const {labelLocale, pageLocales} = usePageLocale(currentUser.current_locale, 'cookieNotice');

  const [visible, setVisible] = useState(localStorage.is_cookie_accepted || false);

  const toggleAgree =() =>{
    localStorage.is_cookie_accepted = true;
    setVisible(!visible);
  }

  if(!visible && currentUser?.id){
    return (
      <div id="ct">
        <div className="cookie-section">
          <div className="flex coldir-xs aligncenter">
            <p className="m-r-30 text-white">
              <strong className="text-white">NOTICE :</strong> 
              {labelLocale(1)}
            </p>
            <button className="btn yellow fw700 f14" onClick={()=>toggleAgree()} type="button" aria-label="Close">
              {labelLocale(2)}
            </button>
          </div>
        </div>
      </div>
    )
  }else{
    return null
  }

}

export default CookieNotice;