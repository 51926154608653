import React, {Fragment, useEffect, useState, useContext} from "react"
import useStyle from "../../hooks/useStyle"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import DigitalContentService from "./DigitalContentService"
import env from "../../env"
import CommonService from "../../services/CommonService"
import PreLoadSpinner from "../../components/PreLoadSpinner"
import ImagePlaceholder from "../Common/ImagePlaceholder"
import {SwiperContainer, SwiperSlide} from "../Common/Swiper"
import querystringify from "querystringify"
import CheckAccess from '../Roles/CheckAccess'
import { DivRichTextView } from "../Common/RichTextView"
import OrganizationMemberService from "../../services/OrganizationMemberService"
import { useCurrentUserHook } from "../Authentication/useUserHook"


let requestParams = {}
const WelcomeTour = (props) => {
  useStyle("slideshow")

  let queryParam = querystringify.parse(props.location.search)

  let cardViewCount = 1
  const {currentUser, screen, dispatch} = useCurrentUserHook()
  const [welcomeTourList, setWelcomeTourList] = useState([])
  const [settings, setSetting] = useState(currentUser.setting)
  const [slideIndex, setSlideIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [currentpage, setCurrentpage] = useState(1) 

  useEffect(() => {
    requestParams = {
      page: 1,
      paginate: 1,
      per_page: 50,
      sort_column: "sort_orders.sort_order",
      sort_direction: "asc",
      search: null
    }
    
    requestParams.journey_type_id = CheckAccess.getJourneyTypeIds(currentUser)

    getWelcomeTour()

    return ()=>{
      requestParams = null
    }
  }, [currentUser.id])

  let getWelcomeTour = () => {
    DigitalContentService.getHelpScreens(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = parseInt(res.data.meta.total);
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount)
        setWelcomeTourList(res.data.digital_contents)
        setLoading(false)
      }
    })
  }
  
  const updateSetting = () => {
    setLoading(true)
    let req = {id: settings.id, welcome_tour: 'Completed'} 
    OrganizationMemberService.updateSetting(req).then((res) => {
      if (res.status == 204) {
        currentUser.setting.welcome_tour = 'Completed';
        dispatch({
          type: "UPDATE",
          payload: { user: currentUser },
        })
        routeUser()
        setLoading(false)
      }
    })
  }

  const routeUser = () =>{
    props.history.push("/health/table")
  }
  
  const ImageSvgRender = ({ content }) => {
    if(content.data.lottie){
      return null
    }else{
      return(
        <ImagePlaceholder 
          imageClass={`img-responsive m-t-15 m-b-15 ${screen.xs ? '' :'img-resize'}`} 
          url={content.data.image?env.file_url + content.data.image:''}/>
      )
    }
  }

  const showSlides = (n) => {
    if(n > (welcomeTourList.length-1)){
      setSlideIndex(0)
    }else if(n < 0){
      setSlideIndex(welcomeTourList.length-1)
    }else{
      setSlideIndex(n)
    }     
  }

  const slide = welcomeTourList.map((w, k) => 
    <SwiperSlide key={k}>
      <div className="mySlides ss-fade bg-white wk-center">
        <div className="ss-numbertext">{k+1} / {welcomeTourList.length}</div>
        {w.data.title && <div className="ss-text font-24 p-10">{w.data.title}<br/></div>}
        <DivRichTextView className="rich-text ss-text font-16" html={w.data.description}></DivRichTextView>
        <div><ImageSvgRender content={w}/></div>
      </div>
    </SwiperSlide>
  )
      
  if(loading){
    return <PreLoadSpinner/>
  }

  return (
    <>
      <div className="page-content bg-white">
        <h4 className="text-center font-18 p-7">New user welcome tour</h4>
        <SwiperContainer currentpage={currentpage} setCurrentpage={setCurrentpage}
          requestParams={requestParams} cardViewCount={cardViewCount} loop={true}
          setActiveIndex={setSlideIndex} onReachEnd={updateSetting} autoHeight="true">
            {slide}
        </SwiperContainer> 
        <div className="col-xs-12">
          <div className="p-20 m-b-40 bg-white text-center clearfix">
            <button className="butn ss-finish" onClick={() =>updateSetting()}>
              {slideIndex+1 == welcomeTourList.length ? 'Finish':'Skip'}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomeTour;