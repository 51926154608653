import React, { Fragment, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import CommentsService from '../../../services/CommentsService';
import CommonService from '../../../services/CommonService';
import useStyle from '../../../hooks/useStyle';
import Pagination from '../../Common/Pagination'
import { SpanRichTextView } from '../../Common/RichTextView';
import { useCurrentUserHook } from '../../Authentication/useUserHook';

// let comment = {};
// let requestParams = {};
const Comments = ({search, journeyProfile}) => {
  useStyle('chat');
  
  const {currentUser, screen} = useCurrentUserHook()
  const [commentsList, setCommentsList] = useState([]);

  let {current:scope} = useRef({requestParams:{}, comment:{}});

  useEffect(() => {
    scope.requestParams = {
      'sort_column': 'updated_at',
      'sort_direction': 'asc',
      'user_id':currentUser.id,
      'page':1,
      'per_page':15,
      'search': search,
      'journey_profile_id':journeyProfile.id
    }
    getNotesList();
  }, [search]);
  
  let getNotesList = (page_no) => {
    scope.requestParams.page = page_no || 1;
    CommentsService.getAll(scope.requestParams).then((res) => {
      if (res.status == 200) {
        scope.requestParams.totalItems = res.data.meta.total;
        setCommentsList(res.data.comments);
        scrollTo(res.data.comments);
      }
    })
  }

  const deleteComment = (comment) => {
    CommentsService.delete(comment.id).then((res)=>{
      setCommentsList(commentsList.filter(i => i.id !== comment.id))
    })  
  }

  function scrollTo(comments){
    if(comments.length>0){
      let scroll_id = comments[comments.length - 1].id;
      let ele = document.getElementById(`comment_${scroll_id}`);
      let topPos = ele.offsetTop;
      document.getElementById('chat-main').scrollTop = topPos;
    }
  }

  const CommentType = ({comment}) =>{
    if(comment.file_upload_id){
      return(<a target="_blank" href={CommonService.getFileUrl(comment.comment)}><img src={CommonService.getIconByType(comment.comment)} width="75px"/></a>)
    }else{
      return(<SpanRichTextView html={comment.comment}></SpanRichTextView>)
    }
  }

  const commentsItem = commentsList.map((c, k) =>
    <Fragment key={k}>
      <div id={`comment_${c.id}`} className={`speech-bubble ${c.created_by == currentUser.id?'speech-left':'speech-right'}`}>
        <CommentType comment={c}/>
        <span className="speech-read mb-3">{c.author && c.author.name} - {CommonService.formatUpdateDate(c.updated_at)}</span>
        { c.created_by == currentUser.id &&
          <div className="m-t-5">
            <div className="font-16 pull-right m-r-5" onClick={e=>deleteComment(c)}>
              <i className="far fa-trash-alt" aria-hidden="true"></i>
            </div>
          </div> }
      </div>
      <div className="clear"/>
    </Fragment>
  )

  const CommentBox = () =>(
    <Fragment>
      <div className="chat-main" id="chat-main" style={{height:screen.height-300}}>
        {commentsList.length==0 && 
          <div className="no-chat font-15">Loading... </div>
        }
        {commentsItem}
      </div>
      <Pagination totalItems={scope.requestParams.totalItems} currentpage={scope.requestParams.page} setCurrentpage={getNotesList} itemsPerPage={scope.requestParams.per_page}/>
    </Fragment>    
  ) 

  return(
    <div className="m-t-15">
      <CommentBox/>
    </div>
  )
  
}

export default Comments;