import React, {useState, useContext, useEffect, useRef, useMemo} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CheckAccess from '../Roles/CheckAccess';
import UserRequestService from "./UserRequestService";
import useModal from "../../hooks/useModal";
import Cs from "../../services/CommonService";
import { useCurrentUserHook } from "../Authentication/useUserHook";

const useUserRegRequestHook = (organizationId, searchString, setSelectedIds, preFilter) => {
	const {currentUser} = useCurrentUserHook()

	let {current:scope} = useRef({'requestParams':{}});

	const [loading, setLoading] = useState(true);
	const [userRequestList, setUserRequestList] = useState([]);
	const [pagemeta, setPagemeta] = useState({});
	const [currentpage, setCurrentpage] = useState(1);
    let [filter, setFilter] = useState(preFilter ? {...preFilter} : {});
    const [apiCallCount, setApiCallCount] = useState(0);
    const [formFields, setFormFields] = useState([]);

	const setParams = (page = currentpage) =>{
    	setCurrentpage(page)
	    scope.requestParams = {
	      'page':currentpage,
	      'per_page':15,
	      'sort_column':'updated_at',
	      'sort_direction':'desc',
	      'organization_id': organizationId || currentUser.current_organization_id, //'50faef7f-c3f0-4a69-8519-c9864548e272'
	      'search':searchString,
	      'filter':filter
	    } 
  	}

	const getUserRequestList = () =>{
	    setLoading(true)
	    UserRequestService.getAll(scope.requestParams).then((res) => {
		    if (res.status === 200) {
		        if(scope.requestParams.response_type === 'csv'){
		        	const csvName = getCsvFileNamePrefix();
			        setSelectedIds && setSelectedIds([]);
			        scope.requestParams.response_type = null;
			        const dlnk = document.getElementById('dwnldLnk');
			        const csv = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(res.data.requests);
			        dlnk.download =  csvName + '-User-Reg-'+ (Cs.getCurrentDateTime('MMM-DD-YYYY')) +'.csv';
			        dlnk.href = csv;
			        dlnk.click();
		        }else{
			        setUserRequestList(res.data.requests);
			        setPagemeta(res.data.meta);
			        setFormFields(res.data.form_fields);
		        }
		        setLoading(false);
		    }
	    })
	}

	const getCsvFileNamePrefix = () =>{
		let csvName = 'csv';
		if(scope.requestParams.organization_id){
		 	const org = currentUser.organizations.find(o => o.id === scope.requestParams.organization_id);
			csvName = org.name;
		}else if(scope.requestParams.filter?.talukas){
			csvName = scope.requestParams.filter.talukas?.join("/")        	
		}else if(scope.requestParams.quiz_taken === "not_in_trainee_users"){
			csvName = 'DNP';
		}
		
		if(scope.assessment?.data?.short_label){
			csvName +=  "_"+scope.assessment.data.short_label;
		}
		return csvName;
	}

	const getTrainingExamineeList = async (req) =>{
		const {status, data} = await UserRequestService.getTrainingExamineeList(req)
		if(status === 200){
			return data;
		}
	}

	const emailStatus = (item) =>{
	    if(item.organization_member_id){
	      return 'Approved'
	    }

	    if(item.is_email_verified){
	      return 'Email Verified'
	    }

	    if(item.is_email_sent){
	      return 'Email Verification sent'
	    }

	    return 'Not Self-Verified'
	}

	return {
	    scope:scope,
	    currentUser,
	    userRequestList, setUserRequestList,
	    currentpage, setCurrentpage,
	    loading, setLoading,
	    pagemeta, setPagemeta,
	    filter, setFilter,
	    apiCallCount, setApiCallCount,
	    setParams,
	    getUserRequestList,
	    emailStatus,
	    getTrainingExamineeList,
	    formFields, setFormFields
	}
}

export default useUserRegRequestHook;