import React, { Fragment, useState, useEffect, useContext, useRef} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import { CacheContext } from "../../contexts/Cache/CacheStateProvider";
import AuthenticationService from "../../services/AuthService";
import useStyle from '../../hooks/useStyle';
import ReactDOM from 'react-dom';
import Cs from '../../services/CommonService';
import env from "../../env";
import { useHistory } from "react-router-dom";
import {useClientSearch} from '../../hooks/useSearch';
import {SideMenuRoute} from "../Routes/Routing";
//import SubFacilityService from '../HealthJourney/MedicalCareFacility/SubFacility/SubFacilityService'
//getMemberSubFacilities 

const OrganizationsModal = (props) => {
  useStyle('dashboard');	

  const { state: { isAuthenticated, user: currentUser }, dispatch} = useContext(AuthContext);
  const { cacheDispatch } = useContext(CacheContext);

  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState(currentUser.organizations || []);
  const {onSearch} = useClientSearch(
    currentUser.organizations,
    setOrganizations 
  );

  const history = useHistory();
  let profileImage = 'images/org.png';

  const updateCurrentOrg = (org, mc, smc) => {
    /*if(!org.is_active) return null*/
    setLoading(true)
    let req = {
      current_organization_id: org.id,
      current_medical_facility_id: mc?.id || null,
      current_sub_facility_id: smc?.id || null,
      context: 'switch_organization',
      current_locale: (org?.data_source?.language?.sid || 'english')
    }
    AuthenticationService.updateUserName(req).then(({status, data}) => {
      if (status === 200) {
        //currentUser.current_organization_id = data.current_organization.id
        //currentUser.current_organization = data.current_organization
        //currentUser.current_member = data.current_member
        //currentUser.current_medical_facility_id = data?.current_medical_facility?.id
        //currentUser.current_medical_facility = data.current_medical_facility

        //Remove all session storage items:
        window.sessionStorage.clear()

        cacheDispatch({
          type: "UPDATE",
          payload: {}
        })

        dispatch({
          type: "UPDATE",
          payload: { user: data.user },
        })
        setLoading(false);
        SideMenuRoute(history, data.user.current_member?.home_page, data.user);
      } 
    })
  }

  useEffect(()=>{
    setTimeout(function() {
      const ele = document.getElementById("active_org");
      if(ele)ele.scrollIntoView();
    }, 500);
  }, [])

  /*useEffect(() => {
    document.addEventListener("click", handleClick, true)
    return () => {
      document.removeEventListener("click", handleClick, true)
    }
  }, [])*/

  /*const handleClick = (e) => {
    if (props.toggleModal && !orgEl.current.contains(e.target)){
      props.toggleModal(e)
    }
  }*/
  
  /*const getSubFacilities = (organization_id, medical_facility_id) =>{
    setSubFacilityList([])
    let req = {
      organization_id: organization_id,
      medical_facility_id: medical_facility_id
    }
    SubFacilityService.getMemberSubFacilities(req).then(({status, data})=>{
      if(status === 200){
        setSubFacilityList(data.sub_facilities)
      }
    })
  }*/

  /*const MedicalSubFacilities = ({org, facility, list=[]}) => list.map((smc, i) => {
    return(
      <Fragment key={i}>
        <div 
          className={`d-flex ptb-13 br-l2 m-l-20 ${currentUser.current_sub_facility_id === smc.id?'org-active':'grey-hov'}`}>
          <span className="fas fa-globe brd-50p font-24"/>
          <div className="m-l-10">  
            <h5 className="d-title">{org.name} - {facility.name} - {smc.name}</h5>
          </div>
          <span onClick={()=>updateCurrentOrg(org, facility, smc)} className="fas fa-plus fa-round-ico ml-auto"/>
        </div>  
      </Fragment>
    )
  })*/  
  
  if(!isAuthenticated)
    return null

  if(loading)
    return <div className="spinner"/>

  return(
    currentUser.organizations.length > 0 ? 
      <OrganizationList {...{currentUser, organizations, updateCurrentOrg, onSearch}}/>
      :
      <div className="lred">Empty</div>
  )
}

const OrganizationList = ({currentUser, organizations, updateCurrentOrg, onSearch}) =>{
  const [openOrgId, setOpenOrgId] = useState()

  const toggleMcFacility = (orgId) =>{
    setOpenOrgId(openOrgId === orgId?null:orgId)
  }

  return(
    <div className="ml-container vh-80 scroll-y">
      <div className="ml-section">
        <div className="content">
          {currentUser.organizations.length > 5 ?
            <input type="text" onChange={(e)=>onSearch(e)} placeholder="Search" className="form-control"/>
            :
            null
          }
          <Organizations {...{currentUser, organizations, updateCurrentOrg, openOrgId, toggleMcFacility}}/>
        </div>
      </div>
    </div>
  )
}

const Organizations = ({organizations=[], currentUser, updateCurrentOrg, openOrgId, toggleMcFacility}) => organizations.map((o, i) => {
  
  const iSCurrentOrg = currentUser.current_organization_id === o.id
  
  if(o.medical_facilities?.length > 0){
    return(
      <Fragment key={i}>
        <div className={`d-flex ptb-13 br-l2 ${iSCurrentOrg?'org-active':'grey-hov'}`} onClick={()=>toggleMcFacility(o.id)}>
          <span className={`far fa-caret-square-${openOrgId?'down':'right'} font-24 highlight`}/>
          <div className={o.is_active?'m-l-10':'op-06 m-l-10'}>  
            <h5 className="pointer">{o.name || o.author.name} - {o.medical_facilities?.length}</h5>
            {!o.is_active && <p className="lred">Inactive</p>}
          </div>
          {iSCurrentOrg?<span className="fas fa-check fa-round-ico ml-auto"/>:null}
        </div>  
        {(o.is_active && openOrgId === o.id) ? 
          <div className="m-l-10">
            <MedicalFacilities key={i} org={o} 
              list={o.medical_facilities} 
              updateCurrentOrg={updateCurrentOrg} 
              currentUser={currentUser}/>
          </div>
          :
          null
        }
      </Fragment>
    )
  }else{
    return(
      <Fragment key={i}>
        <div onClick={()=>updateCurrentOrg(o)} id={iSCurrentOrg?'active_org':i}
          className={`d-flex ptb-13 br-l2 ${iSCurrentOrg?'org-active':'grey-hov'}`}>
          <span className="far fa-caret-square-right font-24 highlight"/>
          <div className={o.is_active?'m-l-10':'op-06 m-l-10'}>  
            <h5 className="pointer">{o.name || o.author.name}</h5>
            {!o.is_active && <p className="lred">Inactive</p>}
          </div>
          {iSCurrentOrg?<span className="fas fa-check fa-round-ico ml-auto"/>:null}
        </div>  
      </Fragment>
    )
  } 
}) 

const MedicalFacilities = ({org, list=[], updateCurrentOrg, currentUser}) => list.map((mc, i) => {
  const isCurrentMf = currentUser.current_medical_facility_id === mc.id
  return(
    <Fragment key={i}>
      <div onClick={()=>updateCurrentOrg(org, mc)} id={isCurrentMf?'active_org':i}
        className={`d-flex ptb-13 br-l2 ${isCurrentMf?'org-active':'grey-hov'}`}>
        <span className="fas fa-arrow-right font-24 highlight"/>
        <div className={mc.is_active?'m-l-10':'op-06 m-l-10'}>  
          <h5>{org.name} - {mc.name}</h5>
          {!mc.is_active && <p className="lred">Inactive</p>}
        </div>
        {isCurrentMf?<span className="fas fa-check fa-round-ico ml-auto"/>:null}
      </div> 
    </Fragment>
  )
}) 

export default OrganizationsModal;