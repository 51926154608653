import React, { Fragment, useState, useEffect, useContext } from "react"
import ReactDOM from 'react-dom'
import { Link, useHistory } from "react-router-dom"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import Cs from '../../../../services/CommonService'
import {PillDsField, PillDsList} from "../../../Common/NotificationPill"
import ReactTooltip from "react-tooltip"
import SortDirection from "../../../Common/SortDirection"
import useStyle from '../../../../hooks/useStyle'
import useSort from "../../../../hooks/useSorting"
import PreLoadSpinner from "../../../PreLoadSpinner"
import HealthScheduleService from "./HealthScheduleService"
import useModal from "../../../../hooks/useModal"
import GenericModal from "../../../../components/Modals/GenericModal"
import { Table, THead, TH, TR, TD, TBody } from "../../../Common/TableView"
import PreScheduleAlert from "../../../PreScheduleEvent/List/PreScheduleAlert"
import ActivityModalLinks from "../../MotherInfantJourney/Activities/List/ActivityModalLinks"
import PaginationSM from "../../../Common/PaginationSM"
import { usePageLocale } from "../../../../hooks/useLocale"
import { LocalizeContext } from "../../../../contexts/Localize/LocalizeStateProvider"
import { useCurrentUserHook } from "../../../Authentication/useUserHook"

let requestParams = {};
let actFormProps = {};
let selectedItem = null;
const UpcomingSchedulesTable = (props) => {
  let history = useHistory();

  const {currentUser} = useCurrentUserHook()
  const {localeDispatch} = useContext(LocalizeContext);

  const [loading, setLoading] = useState(true);
  const [child, setChild] = useState();
  const [journeyProfile, setJourneyProfile] = useState();
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [schedules, setSchedulesList] = useState({});
  const hidePatientInfo = (props.journeyProfileId != null);

  const { isOpen:isPreScheduleOpen, toggleModal:togglePreScheduleModal } = useModal();
  const {labelLocale} = usePageLocale(currentUser.current_locale, 'upcomingVisitsHome');

  useEffect(() => {
    requestParams = {
      'journey_profile_id':props.journeyProfileId,
      'schedule_item_type':'infant_pre_schedule_event',
      'is_future':true,
      'page':currentpage,
      'per_page':100,
      'sort_column': 'start_date',
      'sort_direction': 'asc',
      'page_locale': {
        'data_source_ids':[3943],
        'locale':currentUser.current_locale
      }
    }
    getSchedulesList()
  }, [currentpage]);
  
  const getSchedulesList = () =>{
    setLoading(true)
  	HealthScheduleService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setPagemeta(res.data.meta);
        setSchedulesList(res.data.schedules);
        setJourneyProfile(res.data.journey_profile);
        setChild(res.data.child);
        localeDispatch({
          type: "UPDATE",
          payload: {
            'upcomingVisitsHome':(res.data.page_locale?.cal_col_head_home),
          }
        })
        setLoading(false);
      }
    })
  } 
  
  const {sort} = useSort(requestParams, getSchedulesList);

  let routeJourney = (journey)=>{
    Cs.routeJourney({
    	id:journey.journey_profile_id, 
    	data:{health_condition:2} //Infant Journey
    }, {history})
  }

  const openPreSchedule = (activity) => {
    selectedItem = activity;
    togglePreScheduleModal();
  }

  const toggleProcessTab = (tabKey) =>{
    props.setProcessTabKey(tabKey);
    props.toggleModal();
  }

  /*
  onClick={e=>sort('created_at')}
  <SortDirection sortColumn={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/> 
  onClick={e=>sort('mother_name')}
  <SortDirection sortColumn={requestParams.sort_column} column="mother_name" reverse={requestParams.reverse}/> 
  onClick={e=>sort('baby_name')}
  <SortDirection sortColumn={requestParams.sort_column} column="baby_name" reverse={requestParams.reverse}/> 
  onClick={e=>sort('start_date')}
  <SortDirection sortColumn={requestParams.sort_column} column="start_date" reverse={requestParams.reverse}/> 
  */

  if(isPreScheduleOpen){ 
    return(
      <GenericModal component={PreScheduleAlert} 
      title="Data to collect for the specified date and videos to watch"
      toggleModal={togglePreScheduleModal} 
      isOpen={isPreScheduleOpen}
      preScheduleId={selectedItem.pre_schedule_id}
      journeyProfileId={selectedItem.journey_profile_id}
      schedule={selectedItem}/>
    )
  }

  return(
    <>
  	  {loading ? 
        <div className="spinner"/>
        :
        <>
          <div id="ct" className="table-fix-head vh-80 m-b-5">
            <Table className="shadow-small">
              <THead>
                <TR>
                  {hidePatientInfo ?
                    null
                    :
                    <>
                      <TH>
                        Case Id
                      </TH>
                      <TH label="Name">
                        {labelLocale(1)}
                      </TH>
                      <TH label="Baby Name">
                        {labelLocale(2)}
                      </TH>
                    </>
                    
                  }
                  <TH label="Date">
                    {labelLocale(3)}
                  </TH>
                  {journeyProfile && <TH label="Forms">{labelLocale(5)}</TH>}
                  <TH label="Instructions">
                    {labelLocale(4)}
                  </TH>
                </TR>
              </THead>
              <TBody>
                <TableData {...{schedules, child, journeyProfile, hidePatientInfo, openPreSchedule, routeJourney, labelLocale, toggleProcessTab}} />
              </TBody>
            </Table>
          </div>
          <PaginationSM
            className="pagination-bar p-b-15"
            currentPage={currentpage}
            totalItems={pagemeta.total}
            itemsPerPage={pagemeta.per_page}
            onPageChange={setCurrentpage}
            siblingCount={2}/>
        </>
      }
    </>
  )
}

const TableData = ({schedules, child, journeyProfile, hidePatientInfo, openPreSchedule, routeJourney, labelLocale, toggleProcessTab}) => schedules.length > 0 ? schedules.map((a, k) => {
    return (
      <TR key={k}>
        {hidePatientInfo ?
          null
          :
          <>
            <TD className="text-gray" data-title="Id">
              {a.journey_record_id}
            </TD>

            <TD data-title="Name">
              {a.mother_name}
              {a.status == 'completed' && <span className="fas fa-check m-l-5 badge-2 bg-green"/>} 
            </TD>

            <TD data-title="Name">
              {a.baby_name}
            </TD>
          </>
        }
          
        <TD>
          {Cs.formatUpdateDate(a.start, "MMMM DD YYYY")}
        </TD>

        {journeyProfile && 
          <TD>
            <ActivityModalLinks child={child} scheduleId={a.id}
              journeyProfile={journeyProfile} schedule={a}
              toggleProcessTab={toggleProcessTab}/>
          </TD>
        }
          
        <TD>
          {!journeyProfile && 
            <span data-tip="View details" onClick={()=>routeJourney(a)} 
              className="fa far fa-map font-18 text-muted m-r-15" />
          }
          {a.pre_schedule_id &&
            <span data-tip="Visit Instructions" onClick={()=>openPreSchedule(a)} 
              className="fa fa-info-circle font-18 text-muted" />
          }
        </TD>
      </TR>
    )
  }):<TD className="text-center" colSpan="6">
      {labelLocale(6)}
      {/*There are no upcoming visits scheduled for this maternal-child case.*/}
    </TD>

export default UpcomingSchedulesTable;