import React, { Fragment, useState, useEffect, useRef, useContext, useMemo} from "react"
import ReactDOM from "react-dom"
import { useHistory, useLocation, Link, useRouteMatch } from "react-router-dom"
import useStyle from "../../hooks/useStyle"
import HelpSideNav from "../../components/Help/HelpSideNav"
import useModal from "../../hooks/useModal"
import {usePageTour, setPageTour, resetPageTour} from "../../components/Common/usePageTour"
import env from "../../env"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from "../../services/CommonService"
import {usePageLocale} from '../../hooks/useLocale'
import CheckAccess from '../Roles/CheckAccess' 
import DataSourceService from "../../services/DataSourceService"
import { useCurrentUserHook } from "../Authentication/useUserHook"

const SideMenuBar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const {currentUser} = useCurrentUserHook()
  const { getObjLocale } = usePageLocale(currentUser.current_locale);

  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageTours, setPageTours] = useState([]);
  const [pageTourData, setPageTourData] = useState([]);

  let tour = usePageTour();

  useEffect(() => {
    if (isSideMenuOpen) {
      setIsHelpOpen(false)
      if(pageTours.length == 0){
        getDataSource([3827, 3933], true)
      }
    }
  }, [isSideMenuOpen])

  const permission = useMemo(() =>{ 
    const {permissions} = CheckAccess.destructUser(currentUser);
    return permissions.slide_menu;
  }, [currentUser.id])

  const getDataSource = (ds, is_tour_list) =>{
    setLoading(true);
    resetPageTour(props.pageIds[0]);
    let req = {
      'data_source_params':{
        'locale':currentUser.current_locale,
        'data_source_id':ds, 
        'per_page' : 30
      }
    }
    DataSourceService.getDataSource(req).then((data)=>{
      if(data){
        if(is_tour_list){
          setMenu(data.slide_menu.options);
          setPageTours(data.pages_screen_guide);          
        }else{
          setPageTourData(data);
          const tourKeys = Object.keys(data); 
          tourKeys.forEach((k) => setTour(data[k]));
          openTour();
        }
      }
      setLoading(false);
    })
  }

  const openFeedback = () => {
    history.push(`/feedback?url=${location.pathname}`);
  }

  const findPageTourData = () =>{
    const dataIds = pageTours.options
    .filter(o => props.pageIds.indexOf(o.sid) > -1)
    .map(o => o.child_ds);
    getDataSource(dataIds);
  }

  const openTour = () => {
    setTimeout(function () {
      tour && tour.init(true, props.pageIds[0]);
    }, 1000)
    setSideMenuOpen(!isSideMenuOpen);
  }

  const setTour = (tours) =>{
    tours.options.forEach((t)=>{
      setPageTour(props.pageIds[0], {
        id:t.sid,
        title:t.label,
        description: getObjLocale(t)
      })
    })
  }

  const onMenuSelect = (sid) =>{
    if(sid === 'screen_guide'){
      findPageTourData();
    }else if(sid === 'feedback'){
      openFeedback();
    }else if(sid === 'help'){
      setIsHelpOpen(!isHelpOpen);
    }
    setSideMenuOpen(!isSideMenuOpen);
  }

  if (props.app) {
    props.app.openHelp = (e) => {
      e.preventDefault();
      setIsHelpOpen(!isHelpOpen);
    }
  }

  if(loading)
    return <div className="spinner"/>;

  if (isSideMenuOpen) {
    return ReactDOM.createPortal(
      <div id="slide_help" className="side-menu-bar p-12 brd-10">
        <div onClick={() => setSideMenuOpen(!isSideMenuOpen)}>
          <i className="fas fa-arrow-right"/>
        </div>
          
        {currentUser ? 
          <>
            {menu.map((m, i) => permission?.[m.sid] 
              ? <div key={i} className="element" onClick={() => onMenuSelect(m.sid)}>
                  {getObjLocale(m)}
                </div>
              : null
            )}
          </>
          : null
        }
      </div>, document.body
    )
  } else {
    return ReactDOM.createPortal(
      <>
        <div id="slide_help" className="side-menu-bar p-5" onClick={(e) => setSideMenuOpen(!isSideMenuOpen)}>
          <i className="fas fa-arrow-left"/>
        </div>
        {isHelpOpen && 
          <HelpSideNav helpId={props.helpId} setIsHelpOpen={setIsHelpOpen} />
        }
      </>, document.body
    )
  }
}

export default SideMenuBar;