import React, {Fragment, useState, useContext, useEffect, useRef, useMemo} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../../hooks/useStyle'
import useModal from "../../../hooks/useModal"
import GenericModal from '../../Modals/GenericModal'
import { FontAwesomeIcon2 } from "../../Common/ImageIcons"
import CaseAssessmentForm from './CaseAssessmentForm'
import {FontAwesomeBtn} from "../../Common/Button"
import {CueTip} from "../../Common/CueTooltip"
import CheckAccess from '../../Roles/CheckAccess'
import { useCurrentUserHook } from "../../Authentication/useUserHook"

const AddCaseAssessmentIco = ({count, tooltip, assessUserId, assessmentData, className='', journeyProfileId, children}) => {
  let {current:scope} = useRef({'requestParams':{}})

  const {currentUser} = useCurrentUserHook()

  const {isOpen:isFormOpen, toggleModal:toggleForm} = useModal()

  const isRoleTypePermitted = useMemo(() => 
    CheckAccess.getRoleType(currentUser), [currentUser]
  )

  const permission = useMemo(() =>
    CheckAccess.getMemberPermissionBySid(currentUser, 'case_add_on', 'case_assessment')
  , [currentUser.id])

  const onFormSubmit = (assessment) =>{
    toggleForm()
  }

  const openForm = (id) =>{
    scope.formProps = {
      'match':{
        'params':{
          id,
          'assess_user_id':assessUserId,
          'journey_profile_id':journeyProfileId,
          'organization_id':currentUser.current_organization_id
        }
      },
      assessmentData:assessmentData,
      isPopupMode:true,
      onFormSubmit:onFormSubmit
    }
    toggleForm()
  }

  if(isRoleTypePermitted && permission.write){
    return (
      <>
        {children ? 
          <div className={`${className} tooltip`} onClick={()=>openForm()}>
            {children} 
            {count?<span className="badge-2 up bg-lred">{count}</span>:null}
            <CueTip tooltip={tooltip} positionCss="top"/>
          </div>
          : 
          <FontAwesomeBtn tooltip={tooltip} 
            iconCss="far fa-list-alt" isResponsive={false} name=""
            css={className} colorCss="bg-highlight"
            onSelect={()=>openForm()}>
              {count?<span className="badge-2 down pos-abs bg-lred">{count}</span>:null}
          </FontAwesomeBtn>
        }
        {isFormOpen && 
          <GenericModal component={CaseAssessmentForm} toggleModal={toggleForm} {...scope.formProps}/>
        }
      </>
    )
  }

  return null
}

export default AddCaseAssessmentIco;

{/*<FontAwesomeIcon2 id="assessment" parentCss={className} iconCss="font-18 white far fa-list-alt" onSelect={()=>openForm()}/>*/}
