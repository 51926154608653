import React, { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import DynamicForm from "../../../../FormBuilder/DynamicForm";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import ActivityService from "../ActivityService";
import querystringify from "querystringify";
import FormContainer from '../../../../../components/FormBuilder/FormContainer';
import { useCurrentUserHook } from "../../../../Authentication/useUserHook";

// let formMode = null
// let activity = {}
// let data = {}
// let formFn = {}

function ActivityForm(props) {
  const {currentUser} = useCurrentUserHook()
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let { current: scope } = useRef({ formFn: {}, activity: {}, data: {}, formMode: null });

  let forigenObject =  props.location.state;
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
  //   /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ActivityService.getById({ 'id': params.id }).then((res) => {
      if (res.status == 200) {
        scope.activity = res.data.activity;
        scope.data = scope.activity.data || {};
         //params.form_template_id = activity.activity_form_id;
 
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function (res) {
      scope.activity = null;
    });
  };

  useEffect(() => {
    scope.activity = {};
    scope.formFn = {
      'form_type': "health.activity",
      'journey_profile_id': params.journey_profile_id,
    };
    scope.formMode = "create-form-submissions";
    scope.data = {}
    if (forigenObject) scope.data = Object.assign(forigenObject, scope.data);
    if (params.id) {
      get();
      scope.formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, []);

  const create = (activity) => {
    activity.organization_id = currentUser.current_organization_id
    activity.created_by = currentUser.id;
    activity.updated_by = currentUser.id;
    activity.journey_profile_id = params.journey_profile_id;
    activity.activity_form_id = parseInt(queryParam.form_id);
    activity.activity_type = queryParam.label || "Activity";
    ActivityService.create(activity).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const update = (activity) => {
    activity.updated_by = currentUser.id;
    ActivityService.update(activity).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = () => {
    if(props.isPopupMode){
      props.onFormSubmit()
    }else if(queryParam.activity_form_id){
      props.history.goBack()
    }else{
      props.history.goBack() 
    } 
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm
          formMode={scope.formMode}
          formId={queryParam.form_id}
          form={scope.activity}
          data={scope.data}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
      </FormContainer>
    )
  } else {
    return null;
  }
}

export default ActivityForm;
