import React, { useState, Fragment, useEffect, useRef } from "react"
import ReactTooltip from "react-tooltip"
import querystringify from "querystringify"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import Wellness from "../../Wellness/List/Wellness"
import WellnessService from "../../Wellness/WellnessService"
import Pagination from "../../../Common/Pagination"
import ConfirmMenu from "../../../Modals/ConfirmMenu"
import ProjectMenu from "../../../Modals/ProjectMenu"
import FormTemplateId from "../../../../constants/FormTemplateList"
import CommentsModal from "../../../Modals/CommentsModal"
import { Link } from "react-router-dom"
import useModal from "../../../../hooks/useModal"
import NotesModal from "../../../Modals/NotesModal"
import ChecklistsModal from "../../../Modals/ChecklistsModal"
import useStyle from "../../../../hooks/useStyle"
import CheckAccess from "../../../Roles/CheckAccess"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import Title from "../../GenericPortalPage/Title"
import CalendarModal from "../../../Modals/CalendarModal"
import FilesLinksModal from "../../HealthFilesLinks/FilesLinksModal"
import FilterModal from "../List/WellnessFilterModal"
import WellnessTreeMap from "./WellnessTreeMap"
import WellnessForm from "../Form/WellnessForm"
import GenericModal from "../../../Modals/GenericModal"
import PainTrackingTable from "./PainTrackingTable"
import SideEffectsTable from "./SideEffectsTable"
import SymptomsTable from "./SymptomsTable"

const WellnessBlock = (props) => {

  const {journeyProfile, searchString, componentName, dataSource:component} = props
  const {child_form:formId} = component

  const [ loading, setLoading ] = useState(true)
  const [ symptoms, setSymptomList ] = useState([])
  const [ graphData, setGraphData ] = useState({})
  const [ formFields, setFormFields ] = useState({})
  const [ type, setType ] = useState('')

  const { isOpen: isWellnessFormOpen, toggleModal: toggleWellnessModal } = useModal()
  const { isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal()
  const { isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal } = useModal()
  const { isOpen: isChecklistOpen, toggleModal: toggleChecklistModal } = useModal()
  const { isOpen: isFileLinkOpen, toggleModal: toggleFileLinkModal } = useModal()

  let {current:scope} = useRef({requestParams:{}, selectedItem:{}, dataSources:{}, wellnessFormProps:{}});

  useEffect(() => {
    scope.requestParams = {
      page:1,
      per_page:7,
      sort_column:'updated_at',
      sort_direction:'desc',
      journey_profile_id: journeyProfile.id,
      symptom_form_id:formId,
      form_fields_params: {
        ids:[formId],
        is_table_view:true
      }
    }
    
    if(component.sid == 'pain_tracking'){
      getPainRecords()
    }else if(component.sid == 'symptoms'){
      getSymptomsRecords()
    }else if(component.sid == 'side_effects'){
      getSideEffects()
    }
  }, [props.searchString])

  let getPainRecords = () => {
    setLoading(true)
    scope.requestParams.chart_type = 'pain_tracking'
    scope.requestParams.date_filter = "week"
    scope.requestParams.data_source_params = {
      data_source_id: [3441, 3436, 3470, 3464, 3431, 3428]
    }

    WellnessService.getAll(scope.requestParams).then(({status, data})=>{
      scope.dataSources = data.data_source_list
      setSymptomList(data.symptoms)
      setGraphData({'bar_chart':data.bar_chart, 'line_chart':data.line_chart})
      setFormFields(data.form_fields)
      setLoading(false)
    })
  }

  let getSymptomsRecords = () => {
    setLoading(true)
    scope.requestParams.chart_type = 'symptoms'
    scope.requestParams.date_filter = 'week'
    scope.requestParams.data_source_params = {
      data_source_id: [3672]
    }

    WellnessService.getAll(scope.requestParams).then(({status, data})=>{
      scope.dataSources = data.data_source_list
      setSymptomList(data.symptoms)
      setGraphData({'bar_chart':data.bar_chart, 'line_chart':data.line_chart})
      setFormFields(data.form_fields)
      setLoading(false)
    })
  }

  let getSideEffects = () => {
    setLoading(true)
    scope.requestParams.chart_type = 'side_effects'
    scope.requestParams.date_filter = "week"
    scope.requestParams.data_source_params = {
      data_source_id: [3441, 3436, 3464, 3431, 3470, 3457]
    }

    WellnessService.getAll(scope.requestParams).then(({status, data})=>{
      scope.dataSources = data.data_source_list
      setSymptomList(data.symptoms)
      setGraphData({'bar_chart':data.bar_chart, 'line_chart':data.line_chart})
      setFormFields(data.form_fields)
      setLoading(false)
    })
  }

  const openForm = (path, id) =>{
    scope.wellnessFormProps = {
      match: {
        params: {
          journey_profile_id: journeyProfile.id,
          symptom_id: path.value, //ds value check
          type: path.label,
          form_id: path.child_form,
          id: id || null,
        }
      }
    }
    scope.wellnessFormProps.component = WellnessForm
    toggleWellnessModal()
  }

  const openFileLinks = (e) => {
    e.preventDefault()
    toggleFileLinkModal(e)
  }

  const deleteSymptom = (e) => {
    e.preventDefault()
    WellnessService.delete(scope.selectedItem.id).then((res) => {
      if (res.status == 204) {
        
      }
    })
    toggleDeleteModal(e)
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>

      </Fragment>
    )
  }

  const Footer = ({type}) => (
    <div className={`${type}-card-footer m-t-10 ht-25`} >
      <div className="pull-left d-flex">
        <span className="m-l-15">
         
        </span>
        <span className="m-l-25" onClick={e=>addChecklist(e, type)}>
          <i className="far fa-check-circle font-18 text-muted"></i>
          <span className="badge-2 up bg-lgrey">0</span>
        </span>         
        <span className="m-l-25" onClick={e=>addNotes(e, type)}>
          <i className="far fa-comment font-18 text-muted"></i>
          <span className="badge-2 up bg-lgrey">0</span>
        </span> 
        <span className="m-l-25"  onClick={e=>addMail(e, type)}>
          <i className="fas fa-envelope font-18 text-muted"></i>
          <span className="badge-2 up bg-lgrey">
            0
          </span>
        </span>
        <Link to={{pathname:`/wellness/graph/${journeyProfile.id}`, state:{journeyProfile, component}}} className="m-l-25">
          <i className="fas fa-line-chart font-18 text-muted"></i>
        </Link> 
      </div>
      <div className="pull-right">
        <span className="m-l-25">
          <i data-tip="Menu" className="fa fa-ellipsis-h m-r-15" />
        </span>
      </div>
    </div>
  )

  const Menu = ({ activity }) => (
    <i data-tip="Menu" className="fa fa-ellipsis-h m-r-15"/>
  )

  const addNotes = (e, type) =>{
    setType(type)
    toggleCommentModal(e)
  }
  
  const addMail = (e, type) => {
    props.history.push(`/health/incoming/emails/${type}/${journeyProfile.id}`)
  }

  const addChecklist = (e, graphViewType) => {
    setType(type)
    toggleChecklistModal(e)
  }

  const onFormSubmit = () =>{
    if(component.sid == 'pain_tracking'){
      getPainRecords()
    }else if(component.sid == 'symptoms'){
      getSymptomsRecords()
    }else if(component.sid == 'side_effects'){
      getSideEffects()
    }
    toggleWellnessModal()
  }

  const TreeMap = () =>{
    if(component.sid == 'symptoms'){
      return(
        <SymptomsTable component={component} openForm={openForm} 
          Footer={Footer} requestParams={scope.requestParams}
          symptoms={symptoms} graphData={graphData} 
          dataSources={scope.dataSources} formFields={formFields[formId]}
          addNotes={addNotes} addChecklist={addChecklist}/>
      )
    }else if(component.sid == 'side_effects'){
      return(
        <SideEffectsTable component={component} openForm={openForm} 
          Footer={Footer} requestParams={scope.requestParams}
          symptoms={symptoms} graphData={graphData} 
          dataSources={scope.dataSources} formFields={formFields[formId]}
          addNotes={addNotes} addChecklist={addChecklist}/>
      )
    }else if(component.sid == 'pain_tracking'){
      return(
        <PainTrackingTable component={component} openForm={openForm} 
          Footer={Footer} requestParams={scope.requestParams}
          symptoms={symptoms} graphData={graphData} 
          dataSources={scope.dataSources} formFields={formFields[formId]}
          addNotes={addNotes} addChecklist={addChecklist}/>
      )
    }
  }

  if(isWellnessFormOpen){
    return (
      <GenericModal toggleModal={toggleWellnessModal} 
        isPopupMode="true" onFormSubmit={onFormSubmit} 
        closeConfirmPopup={true} {...scope.wellnessFormProps} 
      />
    )
  }

  return (
    <>
      <div className="p5">
        <div className="app-header flex rowrev">
          <span  data-tip="Create New Activity" onClick={()=>openForm(component)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4">
              <i className="fas fa-plus"></i>
          </span>
        </div>

        <div className="app-content-section">
          {loading ? <div className="spinner"/> : <TreeMap/>}    
        </div>
      </div>

      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
          toggleModal={toggleCommentModal} 
          item={journeyProfile} type={type}/>
      }

      {isChecklistOpen && 
        <ChecklistsModal isOpen={isChecklistOpen} 
          toggleModal={toggleChecklistModal} 
          item={journeyProfile} type={type}/>
      }

      <ConfirmMenu isOpen={isDeleteModalOpen} 
        toggleModal={toggleDeleteModal} 
        success={deleteSymptom}/>
      
      {isFileLinkOpen && 
        <FilesLinksModal toggleModal={toggleFileLinkModal}/>
      }
    </>
  )
}

export default WellnessBlock;