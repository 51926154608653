import React, { useEffect, useState, useContext, useRef } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from "../../../components/FormBuilder/DynamicForm"
import FormTemplateId from "../../../constants/FormTemplateList"
import FacilityService from "./FacilityService"
import FormContainer from '../../../components/FormBuilder/FormContainer'
import {CityState} from '../../Common/CityState'
import GeoLocation from "../../Common/GeoLocation"
import querystringify from "querystringify"
import { useCurrentUserHook } from "../../Authentication/useUserHook"

// let formMode = null
// let facility = {}
// let data = {}
// let formFn = {}

function FacilityForm(props) {
  let { current: scope } = useRef({ formFn: {}});
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const {currentUser} = useCurrentUserHook()
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const get = () => {
    FacilityService.get(params.id).then((res) => {
      if (res.status == 200) {
        scope.facility = res.data.facility
        scope.data = scope.facility.data || {}

        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    }).catch(function (res) {
      scope.facility = null
    })
  }

  useEffect(() => {
    scope.facility = {}
    scope.data = {}
    scope.formMode = "create-form-submissions"
    scope.formFn = {
      form_type: "health.facility",
      journey_profile_id: params.journey_profile_id,
      editPermission:true
    }

    if (params.id) {
      get()
      scope.formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (facility) => {
    facility.organization_id = params.organization_id
    facility.created_by = currentUser.id
    facility.updated_by = currentUser.id

    facility.journey_profile_id = params.journey_profile_id
    facility.form_id = queryParam.form_id?parseInt(queryParam.form_id):FormTemplateId.HealthFacilityForm
    facility.form_type = queryParam.label

    FacilityService.create(facility).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect()
      }
    }).catch((res)=> {

    })
  }

  const update = (facility) => {
    facility.updated_by = currentUser.id
    FacilityService.update(facility).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect()
      }
    }).catch((res)=> {

    })
  }

  const onSubmitPageRedirect = () => {
    if(props.isPopupMode){
      props.onFormSubmit()
    }else{
      props.history.goBack()
     /* props.history.push(
        `/health/list/facilities`
      )  */
    }
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={scope.formMode} 
          formId={queryParam.form_id || FormTemplateId.HealthFacilityForm}
          form={scope.facility} data={scope.data} formFn={scope.formFn} onCreate={create}
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect}
          setPost={setPost} isPopupMode={props.isPopupMode}/>
        <CityState formFn={scope.formFn} data={scope.data} />  
        <GeoLocation geoData={scope.facility}/>
      </FormContainer>
    )
  } else {
    return null;
  }
}

export default FacilityForm;