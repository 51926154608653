import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import HealthCategoryService from "../../HealthCategoryService";
import useStyle from "../../../../hooks/useStyle";
import CheckAccess from "../../../Roles/CheckAccess";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../PreLoadSpinner";
import Cs from "../../../../services/CommonService";
import querystringify from "querystringify";
import useModal from "../../../../hooks/useModal";
import Tabs, { TabPane } from "rc-tabs";
import Title from "../../GenericPortalPage/Title";
import PortalSideMenu from "../HealthPortalPage/PortalSideMenu";
import {NotesBox} from "../../GenericPortalPage/NotesBox";
import ActivityTable from "./ActivityTable";
import GenericModal from "../../../../components/Modals/GenericModal";
import { useCurrentUserHook } from "../../../Authentication/useUserHook";

let dataSource = {};
let formProps = null;

const CancerPortalPage = (props) => {
  useStyle("card");
  useStyle("card_table");
  useStyle("timeline");

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  const {currentUser} = useCurrentUserHook()
  const [currentpage, setCurrentpage] = useState(1);
  const [project, setProject] = useState({});
  const { isOpen: isSideNavOpen, toggleModal: toggleOpenSideNav } = useModal();
  const { isOpen:isNotesOpen, toggleModal:toggleNotesModal } = useModal()

  const [loading, setLoading] = useState(true);
 
  useEffect(() => {
    let req = {
      'data_source_params':{
        "response_type":"object",
        "data_source_id":[3421, 3530]
      },
    }

    HealthCategoryService.getById(params.health_id, req).then((res)=>{
      setLoading(true)
      if(res.status == 200){
        res = res.data;
        //res.event_registration  = res.event_registration;
        //portalPath = res.data_source_list.health_journey_tabs;
        /* if(res.journey_category.education_journey_profiles.data.fj_type_edu_jou == 14){
         }else{
          PortalPath = Teamnodes.indi_path_nodes;
        }*/
        sessionStorage.activeJourney = JSON.stringify(res);
        //CheckAccess.userAccess(currentUser ,res.event_registration, res.invited_journey_member);
        //setStatics(res.event_reports)
        //checkPathAccess(res.journey_category);
        setProject(res.journey_profile)
        setLoading(false)
      }
    })
  }, []);

  const ToggleMenu = () => {
    return (
      <span onClick={(e) => toggleOpenSideNav(e)} className="pathbutton-page z-idx">
        <i className="fa fa-bars p-5"
          data-tip="Access Advanced Child Nutrition Journey Tools"
          aria-hidden="true"></i>
      </span>
    );
  };

 

  if (loading) return <PreLoadSpinner />;

  return (
    <Fragment>
      <div className="content m-t-10 clearfix">
        <div className="col-xs-8">
          <Title title="Overview" project={project} />
        </div>
        <div className="col-xs-4">
         
        </div>
      </div>

      <div className="content row">
        <Tabs defaultActiveKey="0">
          <TabPane tab="Family Info" key="0">
            <div className="time-header ht-120">
              <div className="color-overlay ht-100p">
                <div className="">
                  <span className="font-20">
                    {project.record_id} - 
                    { " "+  project.data.patient_name}
                  </span>
                  <div className="font-14">
                    {project.data.cell_number || "Mobile Not Provided"}
                  </div>
                  <div className="font-14">
                    {project.data.district}, {project.data.state}
                  </div>
                  <div className="font-14">
                    <span className="bold font-18"> Last Updated : </span>{Cs.formatUpdateDate(project.updated_at)}
                    <Link className="round-btn white m-l-15"
                      to={`/health/form/${project.form_id}/${project.id}`}>
                      <i className="fas fa-edit" />
                    </Link>
                    <span className="round-btn white m-l-15" onClick={(e)=>toggleNotesModal(e)}>
                      <i className="fas fa-comment" />
                    </span>
                    <span className="round-btn white m-l-15">
                      <i className="fas fa-microphone" />
                    </span>
                  </div>
                </div>
               
              </div>
            </div>
            <ToggleMenu />
          </TabPane>
          <TabPane tab="Physicians" key="1">
            <div className="time-header ht-120">
              <div className="color-overlay ht-100p"></div>
            </div>
          </TabPane>
          <TabPane tab="Treatment Center" key="2">
            <div className="time-header ht-120">
              <div className="color-overlay ht-100p"></div>
            </div>
          </TabPane>
          <TabPane tab="Transportation" key="3">
            <div className="time-header ht-120">
              <div className="color-overlay ht-100p"></div>
            </div>
          </TabPane>
        </Tabs>
      </div>

      <ActivityTable
        journeyProfile={project}
        history={props.history}
        />

      {isSideNavOpen && (
        <PortalSideMenu
          history={props.history}
          openSideNav={isSideNavOpen}
          setOpenSideNav={toggleOpenSideNav}
          journeyProfileId={project.id}/>
      )}

      {isNotesOpen && <GenericModal title="Notes" component={NotesBox} 
        toggleModal={toggleNotesModal} journeyProfileId={project.id}
        noteableId={project.id}
        currentUser={currentUser} />
      }

      <div className="m-b-80" />
    </Fragment>
  )
}

export default CancerPortalPage;