import React from "react"
import CommonService from '../../services/CommonService'
import {CountryCodes} from "../../constants/CountryCodes"
import SubFacilityService from "../Organizations/MedicalCareFacility/SubFacility/SubFacilityService"
import LocationService from "../../services/LocationService"

const CityState = ({formFn, data, organizationId}) => {

  formFn.health_center_path = 'phc_name'

  formFn.get_health_center = (search, on_success, on_failure) => {
    let req = {
      search: search,
      /*district: data.district,
      taluka: data.taluka*/
    }
    
    LocationService.getPublicHealthCenters(req).then((res)=>{
      on_success(res.data.health_centers)
    })
  }

  formFn.set_health_center = (item) => {
    data.health_center = item.phc_name
    data.health_center_fk_id = item.record_id
  }

  formFn.country_path = 'name'

  formFn.get_country = (search, on_success, on_failure) => {
    LocationService.get_countries({search:search}).then((res)=>{
      on_success(res.data.countries)
    })
  }

  formFn.set_country = (item) => {
    data.country = item.name
    data.country_code = item.iso2
    setPhoneFormat()
  }

  const setPhoneFormat = () =>{
    const f = data.country_code?CountryCodes[data.country_code.toLowerCase()]:null
    formFn.phone_display_format = f?.[4]
    formFn.country_code =  f?.[2]  
  }

  formFn.state_path = 'state_name'

  formFn.get_state = (search, on_success, on_failure) => {
    LocationService.get_state({search:search, country_name:data.country}).then((res)=>{
      on_success(res.data.states)
    })
  }

  formFn.set_state = (item) => {
    data.state = item.state_name
    data.state_code = item.state_code
  }

  formFn.district_path = 'district_name'
  formFn.get_district = (search, on_success, on_failure) => {
    LocationService.get_district({
      country_name:data.country,
      state_name:data.state,
      search:search
    }).then((res)=>{
      on_success(res.data.cities)  
    })  
  }

  formFn.set_district = (item) => {
    data.district = item.district_name
    data.district_code = item.district_code
  }

  formFn.taluka_path = 'sub_district_name'
  formFn.get_taluka = (search, on_success, on_failure) => {
    LocationService.get_taluka({
      country_name:data.country,
      state_name:data.state,
      district_code:data.district_code,
      search:search
    }).then((res)=>{
      on_success(res.data.sub_districts)  
    })  
  }

  formFn.set_taluka = (item) => {
    data.taluka = item.sub_district_name
    data.taluka_code = item.sub_district_code
  }

  formFn.village_path = 'block_village_name'
  formFn.get_village = (search, on_success, on_failure) => {
    LocationService.get_village({
      country_name:data.country,
      state_name:data.state, 
      district_name:data.district, 
      search:search
    }).then((res)=>{
      on_success(res.data.villages)  
    })  
  }

  formFn.set_village = (item) => {
    data.village = item.village_name
    data.village_code = item.village_code
    if(item.block_code){
      data.block = item.block_name
      data.block_code = item.block_code
    }
    if(item.sub_district_name){
      data.taluka = item.sub_district_name
      data.taluka_code = item.sub_district_code
    }
  }

  formFn.sub_facility_path = 'name';

  formFn.get_sub_facility = (search, on_success, on_failure) => {
    const req = {
      page: 1,
      per_page: 100,
      organization_id: organizationId,
      search: search,
      default_fields:'ignore',
      fields:["record_id", "data.name"],
      filter:{
        district: data.district,
        taluka: data.taluka,
        village: data.village
      }
    }
    
    SubFacilityService.getAll(req).then((res)=>{
      on_success(res.data.sub_facilities);
    })
  }

  formFn.set_sub_facility = (item) => {
    data.sub_facility = item.name;
    data.sub_facility_fk_id = item.record_id;
  }

  //awc 
  formFn.awc_name_number_path = "name";
  formFn.get_awc_name_number = (search, on_success, on_failure) => {
    return formFn.get_sub_facility(search, on_success, on_failure);
  }

  formFn.set_awc_name_number = (item) => {
    data.awc_name_number = item.name;
    data.awc_name_number_fk_id = item.record_id;
  }

  return null
}

export {CityState}