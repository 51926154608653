import React, { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import DynamicForm from "../../../../components/FormBuilder/DynamicForm";
import FormTemplateId from "../../../../constants/FormTemplateList";
import ActivityService from "../../../../components/HealthJourney/Health/Activities/ActivityService";
import CommonService from "../../../../services/CommonService"
import {CityState} from "../../../../components/Common/CityState"
import FormContainer from '../../../../components/FormBuilder/FormContainer';

function FamilyForm(props) {
  const { state: authState, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;

  let {current:scope} = useRef({activity:{}, formMode:null, data:{}, formFn:{}});

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ActivityService.getById({id:params.id}).then((res) => {
      if (res.status == 200) {
        scope.activity = res.data.activity;
        scope.data = scope.activity.data || {};
        //params.form_template_id = activity.activity_form_id;

        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function (res) {
      scope.activity = null;
    });
  }

  useEffect(() => {
    scope.activity = {};
    scope.data = {};
    scope.formFn = {
      form_type: "health.activity",
      journey_category_id: params.journey_category_id,
    }
    scope.formMode = "create-form-submissions";
    scope.data = {};

    if (params.id) {
      get();
      scope.formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, []);

  const create = (activity) => {
    activity.created_by = authState.user.id;
    activity.updated_by = authState.user.id;
    activity.journey_profile_id = params.journey_profile_id;
    activity.activity_form_id = FormTemplateId.HealthFamilyForm;
    activity.activity_type = "family";
    ActivityService.create(activity).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect();
      }
    }).catch(function (res) {});
  }

  const update = (activity) => {
    activity.updated_by = authState.user.id;
    ActivityService.update(activity).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    }).catch(function (res) {});
  }

  const onSubmitPageRedirect = () => {
    props.history.push(
      `/infant/portal/${params.journey_profile_id}`
    )
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={scope.formMode} formId={FormTemplateId.HealthFamilyForm}
          form={scope.activity} data={scope.data} formFn={scope.formFn} onCreate={create}
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect}
          setPost={setPost}/>
      
        <CityState data={scope.data} formFn={scope.formFn}/>
      </FormContainer>
    )
  } else {
    return null;
  }
}

export default FamilyForm;