import React, { Fragment, useState, useEffect, useContext} from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import useStyle from "../../hooks/useStyle";
import CommonService from "../../services/CommonService";
import useEscape from "../../hooks/useEscape";
import FormRuleAlertService from "../../services/FormRuleAlertService";
import PlannerService from "../HealthJourney/Health/Planners/PlannerService";
import PlannerStepService from "../HealthJourney/Health/Planners/PlannerStepService";
import CounselingService from "../HealthJourney/Counseling/CounselingService";
import {useSearch} from '../../hooks/useSearch';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import PaginationSM from '../Common/PaginationSM'
import Tabs, { TabPane } from "rc-tabs";
import { useCurrentUserHook } from "../Authentication/useUserHook";

const FormRuleAlertAssignment = ({isOpen, toggleModal, formRule, alertType, formId}) => {
  
  useStyle("filter_dialog");

  const {currentUser} = useCurrentUserHook()

  let [loading, setLoading] = useState(true);
  let [counselings, setCounselings] = useState([]);
  let [selectedCounselings, setSelectedCounselings] = useState([]);
  let [pagemeta, setPagemeta] = useState({});
  let [currentpage, setCurrentpage] = useState(1);

  const {onSearchCallback, searchKeyword} = useSearch(() => search());

  useEscape(toggleModal);

  const COUNSELING_TYPE = 'infant_journey.counseling'
  const title = (alertType === COUNSELING_TYPE)?'counseling':'';

  useEffect(() => {
    if(alertType === COUNSELING_TYPE){
      getCounselings(searchKeyword, null, setCounselingResponse);
    }
  }, [currentpage])

  useEffect(()=>{
    //Get selected counseling alert
    if(alertType === COUNSELING_TYPE){
      getCounselings(
        searchKeyword, 
        {'exist_in_form_rule_alert':true, 'form_rule_id':formRule?.id}, 
        setSelectedCounselingResponse
      );
    }
  }, [])

  let search = () =>{
    if(alertType === COUNSELING_TYPE){
      getCounselings(searchKeyword, null, setCounselingResponse);
    }
  }

  let getCounselings = (search, filter, callback) => {
    setLoading(true)
    const req = {
      'default_fields':'ignore',
      'fields':['id', 'data_source', 'record_id', 'counseling_form_id', 'form_rule_alert_id'],
      'search':search,
      'search_column':'data_source.stage,data_source.step',
      'filter':filter,
      'page':currentpage,
      'per_page':30,
      'is_template':true,
      'form_rule_id':formRule?.id
    }

    CounselingService.getAll(req).then((res) => {
      callback(res.data)
    })
  }

  const setCounselingResponse = (data) =>{
    setCounselings(data.counselings);
    setPagemeta(data.meta);
    setLoading(false);
  }

  const setSelectedCounselingResponse = (data) =>{
    setSelectedCounselings(data.counselings);
    setLoading(false);
  }

  let toggle = (d, itemType) =>{
    if(d.form_rule_alert_id){
      destroy(d)
    }else{
      create(d, itemType)
    }
  }

  let create = (item, itemType) =>{
    setLoading(true)
    let req = {
      'form_rule_id':formRule?.id,
      'organization_id':currentUser.current_organization_id,
    }

    if(itemType === 'counseling'){
      req.counseling_id = item.id;
    }

    FormRuleAlertService.create(req).then(({status, data})=>{
      if(status === 201){
        if(itemType === 'counseling'){
          item.form_rule_alert_id = data.form_rule_alert.id;
          setSelectedCounselings([
            {
              'id': item.id,
              'record_id': item.record_id,
              'counseling_form_id': item.counseling_form_id,
              'data_source': item.data_source,
              'form_rule_alert_id': data.form_rule_alert.id,
            }, 
            ...selectedCounselings
          ])
          setLoading(false);
        }
      }
    })
  }

  let destroy = (alert) =>{
    setLoading(true);
    FormRuleAlertService.delete(alert.form_rule_alert_id).then((res)=>{
      if(res.status == 204){
        alert.form_rule_alert_id = null
        setSelectedCounselings(
          selectedCounselings.filter(c => c.form_rule_alert_id !== alert.form_rule_alert_id)
        )
        setLoading(false);
      }
    })  
  }

  const Counselings = ({counselings}) => counselings.map((o, k) =>
    <span className="checkbox-container" key={k} onClick={e => toggle(o, 'counseling')}>
      <span>
        {o.data_source?.stage?.label} - {o.data_source?.language?.label}
        <br/>
        {o.data_source?.step?.label} - {o.record_id} - {o.counseling_form_id}
      </span>
      <input type="checkbox" checked={o.form_rule_alert_id} readOnly/>
      <span className="checkbox-checkmark"></span>
    </span>
  )

  return ReactDOM.createPortal(
    <>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom brd-10">
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Please select {title}</h4>
            <button className="btn-close" title="Close Modal" onClick={e=>toggleModal(e)}>
              ×
            </button>
          </div>
          {loading?<div className="spinner"/>:null}
          <div className="p-5 scroll-x">
            <Tabs moreIcon="+">
              <TabPane tab="Search" key="1">
                <div className="input-group m-b-20">
                  <input type="text" onChange={e=>onSearchCallback(e)} placeholder="Search" className="form-control input-sm"/>
                  <span className="input-group-btn">
                    <p type="button" className="bg-highlight input-sm">
                      <i className="fa fa-search white"/>
                    </p>
                  </span>
                </div>  
                <div className="vh-70 scroll-y">
                  <Counselings counselings={counselings}/> 
                </div>
                <PaginationSM
                  className="pagination-bar"
                  currentPage={currentpage}
                  totalItems={pagemeta.total}
                  itemsPerPage={pagemeta.per_page}
                  onPageChange={setCurrentpage}
                  siblingCount={2}/>
              </TabPane>
              <TabPane tab="Selected" key="2">
                <div className="vh-70 scroll-y">
                  <Counselings counselings={selectedCounselings}/> 
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </>, document.body
  )
}

export default FormRuleAlertAssignment;