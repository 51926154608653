import React, { useState, useEffect, Fragment, useContext, useMemo} from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import {Link, useHistory} from "react-router-dom";
import UserRequestService from "./UserRequestService";
import Cs from "../../services/CommonService";
import {Table, THead, TH, TR, TD, TBody} from "../Common/TableView";
import {LinkFontAwesomeIcon, LinkDeleteIcon, CheckBtn} from "../Common/ImageIcons";
import PaginationSM from '../Common/PaginationSM';
import CheckAccess from '../Roles/CheckAccess';
import OrganizationMemberService from "../Organizations/Members/OrganizationMemberService";
import useModal from "../../hooks/useModal";
import ConfirmMenu from "../Modals/ConfirmMenu";
import {ActiveFilters, FilterBtn} from "./Filter";
import {NavIcon} from "../Common/MenuItem";
import {CountryCodes} from "../../constants/CountryCodes";
import FormHelper from '../FormBuilder/FormHelpers';
import useSort from '../../hooks/useSorting';
import SortDirection from '../Common/SortDirection';
import useUserRegRequestHook from './useUserReqRequestHook';
import {ReadCsvFile, CsvTableView} from "../Common/ReadCsvFile";
import FormTemplateId from "../../constants/FormTemplateList";
import GenericModal from '../Modals/GenericModal';
import UserSurveyScoring from './UserSurveyScoring';
import {PillList} from '../Common/NotificationPill';
import {DataSourceMenu} from '../Modals/DataSourceMenu';
import {CommentsBtn} from "../Modals/Comments";
import {useSubscriptionAccess} from "../Subscriptions/useSubscription";
import {useOrgUrlAccessHook} from "../Organizations/OrgUrlAccess/OrgUrlAccessHook";
import { CueTip } from '../Common/CueTooltip';
import { useCurrentUserHook } from '../Authentication/useUserHook';
import { HelpIcon } from '../Help/HelpSidePanel';

const UserRequestList = (props) => {
  let params = props.match.params;

  const {currentUser} = useCurrentUserHook();

  const [showRegFormLink, setShowRegFormLink] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [csvFormFields, setCsvFormFields] = useState([]);
  const [parsedCsvDataList, setParsedCsvDataList] = useState([]);

  const {toggleListString, existsListString} = FormHelper;
  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal } = useModal();
  const {isOpen:isCsvUploadOpen, toggleModal:toggleCsvUpload } = useModal();
  const {isOpen:isCsvTableViewOpen, toggleModal:toggleCsvTableView } = useModal();
  const {isOpen:isScoringPopup, toggleModal:toggleScoringPopup } = useModal();
  const {isOpen:isInvalidStatusPopup, toggleModal:toggleInvalidStatusPopup} = useModal();

  const {getUserRegForm} = useSubscriptionAccess();
  const {checkUrlAccess} = useOrgUrlAccessHook(currentUser.current_organization_id);

  const permission = useMemo(() => 
    CheckAccess.getMenuPermission(currentUser, 'user_reg_request'), [currentUser.id]
  )

  const preFilter = useMemo(() => {
    return {
      /*"is_active": true*/
      /*"talukas": CheckAccess.getMfTalukas(currentUser),
      "role_record_ids": CheckAccess.getRoleIdsToSupervise(currentUser)*/
      //"medical_facility_ids": currentUser.current_member?.has_all_facility_access ? [] : org?.medical_facilities?.map(f=>f.id)
    };
  }, [currentUser?.id])

  const {
    scope,
    setParams,
    userRequestList, setUserRequestList,
    currentpage, setCurrentpage,
    loading, setLoading,
    pagemeta, setPagemeta,
    filter, setFilter,
    getUserRequestList, emailStatus
  } = useUserRegRequestHook(params.organization_id, props.searchString, setSelectedIds, preFilter)

  useEffect(()=>{
    checkRegUrlAccess();
  }, [])

  useEffect(() => {
    if(scope.requestParams.page !== currentpage && permission?.read){
      setParams(currentpage);
      getUserRequestList();
    }
  }, [currentpage])

  useEffect(() => {
    if(!loading){
      setParams(1); //set page to 1
      if(filter.duplicate_user) scope.requestParams.sort_column = "user_requests.data.name";
      getUserRequestList();
    }
  }, [props.searchString, filter])
  
  const {sort} = useSort(scope.requestParams, getUserRequestList);

  const downloadAsCsv = () => {
    scope.requestParams.page = null;
    scope.requestParams.per_page = null;
    scope.requestParams.response_type = 'csv';
    scope.requestParams.filter = {
      'ids':selectedIds,
       ...filter
    }
    getUserRequestList();
  }

  const checkRegUrlAccess = async () =>{
    const {status} = await checkUrlAccess("examinee_registration");
    if(status === 200) setShowRegFormLink(true);
  }
  
  const addMemberToOrg = (request, index) =>{
    const req = {
      'id': request.id,
      'add_to_org_member':true
    }
    UserRequestService.update(req).then(({status, data}) => {
      if (status == 201) {
        userRequestList[index].org_member_id = 1;
        setUserRequestList([...userRequestList]);
      }
    })
  }

  const update = (req, index) =>{
    UserRequestService.update(req).then(({status, data}) => {
      if (status == 201) {
        userRequestList[index] = data;
        setUserRequestList([...userRequestList]);
      }
    })
  }

  const onDelete = (r) => {
    setSelectedItem(r);
    toggleDeleteModal();
  }

  const deleteRequest = (e) => {
    e.preventDefault();
    setLoading(true);
    UserRequestService.delete(selectedItem.id).then((res) => {
      if (res.status == 204) {
        setUserRequestList(userRequestList.filter(i => i.id !== selectedItem.id));
        setLoading(false);
      }
    })
    toggleDeleteModal(e);
  }

  const sendToWhatsApp = (request) =>{
    if(request.data?.mobile_number){
      const code = CountryCodes.in[2];
      window.open(`https://wa.me/${code}${request.data.mobile_number}?text=hi`);
    }
  } 

  const sendInvite = (request) =>{
    setLoading(true);
    UserRequestService.sendVerifyEmail({
      'id':request.id,
    }).then(({status})=>{
      if(status === 201){
        alert("Success");
        setLoading(false);
      }
    })
  }

  const onCsvReadSuccess = (contents) =>{
    setLoading(true);
    let req = {
      user_id: currentUser.id,
      organization_id:currentUser.current_organization_id,
      contents:contents
    }
    UserRequestService.uploadCsv(req).then(({status, data}) => {
      if (status === 200) {
        setParsedCsvDataList(data.parsed_rows);
        setCsvFormFields(data.form_fields);
        toggleCsvTableView();
        setLoading(false);
      }else if(status == 433){
        alert(data.error);
        setLoading(false);
      }
    })
  }

  const insertParsedCsv = () => {
    setLoading(true);
    UserRequestService.bulkInsert({
      form_id: FormTemplateId.UserRequestForm,
      organization_id: params.organization_id,
      users:parsedCsvDataList
    }).then((res) => {
      if (res.status === 201) {
        getUserRequestList();
        toggleCsvTableView();
        setLoading(false);
      }else{
        setLoading(false);
        alert(res.data.error || 'Upload Error');
      }
    })
  }

  const routeForm = (request) =>{
    const url = (
      request.form_id === FormTemplateId.ExamineeRegForm
      ? `/examinee/registration/${request.organization_id}/${request.id}` 
      : `/user/request/${request.organization_id}/${request.id}`
    ) 
    props.history.push(url);
  }

  const openScoringPopup = (request) =>{
    scope.selectedUserReq = request;
    toggleScoringPopup();
  }

  const toggleVerify = async (idx) =>{
    const {status} = await UserRequestService.update({
      "id": userRequestList[idx].id, 
      "is_verified": !userRequestList[idx].is_verified,
      "verified_user_id": currentUser.id
    });
    if(status === 201){
      userRequestList[idx].is_verified = !userRequestList[idx].is_verified;
      setUserRequestList([...userRequestList]);
    }
  }

  const openInvalidStatusPopup = (request, idx) =>{
    scope.selectedUserReq = request;
    scope.selectedIdx = idx;
    toggleInvalidStatusPopup();
  }

  const updateInvalidStatus = (ds) =>{
    const req = {
      "id":scope.selectedUserReq.id,
      "invalid_status_dsl_id":ds.pk_id,
      "is_active": false //inactive when invalid
    }

    if(ds.label.includes("remove flag")){
      req.invalid_status_dsl_id = null;
      req.is_active = true;
    }

    update(
      req, 
      scope.selectedIdx
    );
    toggleInvalidStatusPopup();
  }

  const tableData = userRequestList.map((r, k) => {
    const data = r.data || {};

    return(
      <TR key={k}>
        <TD>
          <div>{r.record_id}</div>
        </TD>
        <TD className="ellipsis-lg">
          <span className="bold pointer">
            {data.name}
            {permission?.verify
              ? <div className="flex gap-10">
                  {r.is_verified?<span className="circle-sm-2 bg-light-green white tooltip">V<CueTip tooltip="User Verified" positionCss="right"/></span>:null}
                  {r.organization_member_id?<span className="circle-sm-2 bg-light-green white tooltip">A <CueTip tooltip="Account Activated" positionCss="right"/></span>:null}
                  {r.last_login_date?<span className="circle-sm-2 bg-light-green white tooltip">L <CueTip tooltip="User has logged in" positionCss="right"/></span>:null}
                </div>
              : null
            }
          </span>
          <div className="m-t-10">
            <div>
              {data.email}
            </div>
            <div>
              {data.mobile_number}
            </div>
          </div>
          <div>
            {data.role} 
          </div>
          <div className="iflex coldir scroll-x mxh-100">
            <PillList list={data.phc_array} dataKey="phc_name" style="badge-2"/>
          </div>
        </TD>
        <TD>
          <div>{data.role}</div>
        </TD>
        <TD>
          {emailStatus(r)}
        </TD>
        <TD>
          {Cs.formatUpdateDate(r.created_at)}
        </TD>
        <TD>
          <div className="flex coldir">
            <div className="flex aligncenter">
              <LinkFontAwesomeIcon id="edit" tooltip="Edit" onSelect={()=>routeForm(r)} 
              parentCss="m-r-10 pull-right bg-lgrey" iconCss="fas fa-edit white"/>
              {permission?.verify ?
                <>
                  <LinkFontAwesomeIcon parentCss="m-r-10 pull-right bg-lgrey" 
                    tooltip="Duplicate User"
                    onSelect={()=>openInvalidStatusPopup(r, k)}
                    iconCss={`font-18 fas fa-flag ${r.invalid_status_dsl_id ? 'red':'white'}`}/>
                  <CheckBtn tooltip="User Verified" onSelect={()=>toggleVerify(k)} 
                    exists={()=> r.is_verified}/> 
                  <LinkFontAwesomeIcon parentCss="m-l-10 pull-right bg-lgrey" 
                    onSelect={()=>sendToWhatsApp(r)}
                    iconCss="white font-18 fab fa-whatsapp"/>
                </>
                :
                null
              }
            </div>
            <div className="flex aligncenter m-t-10">
              {permission?.write 
                ? <>
                    <CheckBtn css="m-l-5 m-r-5" tooltip="Select" 
                      onSelect={()=>toggleListString(r.id, selectedIds, setSelectedIds)} 
                      exists={()=> existsListString(r.id, selectedIds)}/>
                    
                    {r.org_member_id
                      ? <LinkFontAwesomeIcon id="email" tooltip="Verifiy Email" 
                          onSelect={()=>sendInvite(r)}
                          parentCss="m-l-10 bg-lgrey"
                          iconCss="fas fa-sms white"/>
                      : null
                    }
                    
                    <LinkFontAwesomeIcon parentCss="m-l-10 pull-right bg-lgrey" 
                      onSelect={()=>openScoringPopup(r)}
                      iconCss="white font-18 fas fa-chart-bar"/>
                  </>
                : null
              }
              {!r.org_member_id && r.data.email && permission.add_to_org_member 
                ? <LinkFontAwesomeIcon onSelect={()=>addMemberToOrg(r, k)} 
                    tooltip="Add Member" 
                    parentCss="m-l-10 bg-lgrey"
                    iconCss="fas fa-user-plus white"/>
                : null
              }
              {permission?.delete 
                ? <LinkDeleteIcon id="delete" tooltip="Delete" onSelect={()=>onDelete(r)}/>
                : null
              }
            </div>
          </div>
        </TD>
      </TR>
    )
  })

  const SortDirIco = ({column}) =>{
    return <SortDirection sortColumn={scope.requestParams.sort_column} 
      column={column} reverse={scope.requestParams.sort_direction}/>
  }

  const errorEle = (client_id, row) => (
    <>
      {row.role_error?<span className="lred bold-500">{row.role_error}</span> : null}
      {row.medical_facility_error?<span className="lred bold-500">{row.medical_facility_error}</span> : row.data.medical_facility_error}
      {row.error?<span className="lred bold-500">{row.error}</span> : null}
    </>
  )

  const RegLink = ({localeSid}) =>{
    if(!showRegFormLink) return null; //IAPSM ORG
    //const formDs = getUserRegForm();
    localeSid = localeSid || 'english';
    const qp = `?locale=${localeSid}`;
    return(
      <Link id="Link" tooltip="User Registration" 
        to={`/examinee/registration/${currentUser.current_organization.id}${qp}`} 
        className="highlight underline f16">
          View registration form
      </Link>
    )
  }

  return(
    <div id="ct">  
      <div className="app-content">
        <div className="app-header flex justspacebetween coldir-xs m-t-15">
          <div>
            <div className="app-title m-r-20">
              User Registration Dashboard - {currentUser.current_organization?.data?.name}
            </div>
            <div className="flex justspacebetween aligncenter m-t-5">
              <span className="font-14">{pagemeta?.total} Items</span>
            </div>
            <div> <RegLink localeSid={currentUser.current_organization.data_source?.language?.sid}/></div>
            <ActiveFilters filter={filter} setFilter={setFilter}/>
          </div>
          <div className="flex aligncenter justifyflexend m-t-10-xs">
            <CommentsBtn item={currentUser.current_organization} 
              currentUser={currentUser} 
              commentableType="user_request_page" 
              commentableId={currentUser.current_organization_id}
              className="bg-highlight round-btn float-right m-r-15 tooltip"
              badgeClassName="bg-black-light-1"/>

            {permission?.write ?
              <>
                <NavIcon id="survey" dataTip="Survey" 
                  className="bg-highlight round-btn m-r-15" 
                  icon="fab fa-wpforms"
                  link={`/list/user/survey/${currentUser.current_organization_id}`}/>

                <NavIcon id="leaderboard" dataTip="Leaderboard" 
                  className="bg-highlight round-btn m-r-15" 
                  icon="fab fa-wpforms"
                  link={`/user/registration/leaderboard/${currentUser.current_organization_id}`}/>
                
                <span id="upload_btn" onClick={()=>toggleCsvUpload()} 
                  data-tip="Upload Csv File" 
                  className="bg-highlight round-btn">
                    <i className="fas fa-upload"/>
                </span>
              </>
              : 
              null
            }

            <FilterBtn filter={filter} setFilter={setFilter} 
              organizationId={currentUser.current_organization_id} 
              requestParams={scope.requestParams} 
              getList={getUserRequestList}
              preFilter={preFilter}/>

            {permission?.csv_download ?
              <NavIcon id="csv" dataTip="CSV Download"
                onSelect={() => downloadAsCsv()}
                className="bg-highlight round-btn float-right m-l-15"
                icon="fas fa-download" />
              :
              null
            }

            <HelpIcon recordIds={80} showBtn={true}/>
          </div>
        </div>

        <div className="app-content-section m-t-15 table-fix-head">
          {loading ? <div className="spinner"/>:null}
          <Table className="shadow-small">
            <THead>
              <TR>
                <TH onClick={()=>sort('record_id')}>
                  Reg ID <SortDirIco column="record_id"/>
                </TH>
                <TH onClick={()=>sort('user_requests.data.name')}>
                  Name <SortDirIco column="data.name"/>
                </TH>
                <TH onClick={()=>sort('user_requests.data.role')}>
                  Role/PHC <SortDirIco column="data.role"/>
                </TH>
                <TH>Status</TH>
                <TH onClick={()=>sort('user_requests.created_at')}>
                  Created Date <SortDirIco column="created_at"/>
                </TH>
                <TH>Actions</TH>
              </TR>
            </THead>
            <TBody>
              {tableData}
            </TBody>
          </Table>
        </div>

        <PaginationSM
          className="pagination-bar m-t-10"
          currentPage={currentpage}
          totalItems={pagemeta.total}
          itemsPerPage={pagemeta.per_page}
          onPageChange={setCurrentpage}
          siblingCount={2}/>

        <ConfirmMenu isOpen={isDeleteOpen} 
          toggleModal={toggleDeleteModal} 
          success={deleteRequest}/>    
      </div>

      {isCsvUploadOpen && 
        <ReadCsvFile 
        isFileUploadOpen={isCsvUploadOpen} 
        onReadCallback={onCsvReadSuccess} />
      }

      {isCsvTableViewOpen && 
        <GenericModal component={CsvTableView} loading={loading}
        isOpen={isCsvTableViewOpen} toggleModal={toggleCsvTableView}
        formFields={csvFormFields} dataList={parsedCsvDataList}
        title="Preview of the list of facilities to be uploaded" 
        widthCss="w-99p" insertParsedCsv={insertParsedCsv}
        csvSpecialFields={[{label:'Error', client_id:'error', ele:errorEle}]}/>
      }

      {isScoringPopup
        ? <GenericModal component={UserSurveyScoring} userRequest={scope.selectedUserReq}
          isOpen={isScoringPopup} toggleModal={toggleScoringPopup}/>
        : null
      }

      {isInvalidStatusPopup 
        ? <DataSourceMenu id="4651" toggleModal={toggleInvalidStatusPopup} 
            onSelect={updateInvalidStatus}
            activeDslId={scope.selectedUserReq.invalid_status_dsl_id}/>
        : null
      }

    </div>
  )

}

export default UserRequestList;