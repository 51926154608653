import React, {PureComponent, Fragment, useState, useEffect, useRef} from "react"
import {Link} from "react-router-dom"
import moment from 'moment'
import importScript from '../../../../hooks/importScript'
import WellnessService from "../WellnessService"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"

const WellnessLineGraph = ({type, dataSource, report, reportList}) => {
  /*if(type == "pain"){
    reportList = dataSource.area_complaint.options
  }else if(type == "daily"){
    reportList = dataSource.daily_journal_items.options
  }else{
    reportList =dataSource.side_effects_multiselect.options
  }*/
  const [loading, setLoading] = useState(true)
  
  let {current:scope} = useRef({req:{}, dataSourceList:{}, yaxisLabels:[], options:{}});

  useEffect(() => {
    importScript('/scripts/apexcharts/apexcharts.min.js', getChartData)

    scope.options = {
      series: [{
        data: [],
      }],
      chart: {
        toolbar: {
          show: false,
        },
        height: 150,
        type: 'line',
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: false,
        }
      },
      stroke: {
        curve: 'smooth'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], 
          // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        type: 'datetime',
      },
      /*dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          const result = options.series[0].data.find((o)=>{
            return o.y == val
          });
          return result.datalabels
        }
      }*/
    };

    /*
    yaxis: {
        labels: {
          formatter: function (val, opts) {
            const result = options.series[0].data.find((o)=>{
              return o.datalabels
            });
            return result.datalabels        
          }
        },
      },
    
    */
  }, [])


  function getChartData(date_filter){
    setYaxis()
    setLoading(false)
    /*setWellnessReport(null)
    if(type == "pain"){
      req = {
        chart_type:'pain_line_chart',
        date_filter:date_filter || 'month',
        data_source_params: {
          data_source_id: [3464, 3431, 3428],
        }
      }
    }else if(type == "daily"){
      req = {
        chart_type:'daily_routine_line_chart',
        date_filter:date_filter || 'month',
        data_source_params: {
          data_source_id: [3464, 3431, 3428,3470],
        }
      }
    }else{
       req = {
        date_filter:date_filter || 'month',
        data_source_params: {
          data_source_id: [3464, 3431, 3457],
        }
      }
    }

    WellnessService.report(req).then((res)=>{
      dataSourceList = res.dataSourceList
      setYaxis()
      setWellnessReport(res.report)
      setLoading(false)
    })*/
  }

  const setYaxis = () =>{
    if(type == "pain_tracking"){
      scope.yaxisLabels = dataSource.pain_level.options
      scope.options.yaxis = {
        tickAmount:4,
        min:1,
        max:5,
        labels: {
          show: true,
          formatter: function (val, opts) {
            val = val.toFixed(0);
            const result =scope.yaxisLabels.find((o)=>{
              return o.value == val
            });
            return result?result.label:'Nil'        
          }
        }
      }
    }else if(type == "daily"){
      scope.yaxisLabels = dataSource.daily_journal_items.options
      /*options.yaxis = {
        tickAmount:4,
        min:1,
        max:5,
        labels: {
          show: true,
          formatter: function (val, opts) {
            val = val.toFixed(0);
            console.log('val yaxisLabels',yaxisLabels,val,opts)
            const result =yaxisLabels.find((o)=>{
              return o.value == val
            });
            return result?result.label:'Nil'        
          }
        }
      }*/
    }else if(type == "side_effects"){
      scope.yaxisLabels = dataSource.memory_loss.options
      scope.options.yaxis = {
        tickAmount:3,
        min:1,
        max:4,
        labels: {
          show: true,
          formatter: function (val, opts) {
            val = val.toFixed(0);
            const result = scope.yaxisLabels.find((o)=>{
              return o.value == val
            });
            return result?result.label:'Nil'        
          }
        }
      }  
    }
  }

  const TimeSeriesChart = ({data, id}) => {
    let data_key;
    if(data.modal_name){
      data_key = data.modal_name
    }else{
      data_key = data.label.toLowerCase()
    }

    if(report[data_key] == null){
      return (<div>No Records Found</div>)
    }else{
      setChart(id, report[data_key])
      return (<div id={'chart_'+id}></div>)
    }  
  }

  function setChart(id, data){
    setTimeout(function() {
      scope.options.series[0].data = data
      var chart = new window.ApexCharts(document.querySelector(`#chart_${id}`), scope.options)
      chart.render() 
    }, 0)
  }

  const Header = ({data}) =>{
    const cardStyle = {
      height: '40px',
      textAlign:'center',
      backgroundColor: (data.bg_color || '#607D8B')
    }

    return(
      <div className="white card-header" style={cardStyle}>
        <div className="font-16">
          {data.label}
        </div>
      </div>
    )
  }

  const Filters =()=>{
    return(
      <div className="col-xs-12">
        <button className={`filter ${scope.req.date_filter=='day' && 'active'}`} onClick={e=>getChartData('day')}>D</button>
        <button className={`filter ${scope.req.date_filter=='week' && 'active'}`} onClick={e=>getChartData('week')}>W</button>
        <button className={`filter ${scope.req.date_filter=='month' && 'active'}`} onClick={e=>getChartData('month')}>M</button>
        <button className={`filter ${scope.req.date_filter=='year' && 'active'}`} onClick={e=>getChartData('year')}>Y</button>
      </div>
    )
  }

  const GraphContainer = () => reportList.map((w, i) => (
    <div className="col-xs-12 col-sm-6 m-b-10 p-15 bg-white" key={i}>
      <Header data={w}/>
      <TimeSeriesChart data={w} id={i}/>
    </div>
  ))

  return(
    <Fragment>
      <Filters/>
      {loading?<div className="spinner"/>:<GraphContainer/>}
    </Fragment>
  )
 
}

export default WellnessLineGraph;