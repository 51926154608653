import React, { useState, useEffect, Fragment} from 'react'
import {CheckBox, OptionsObjList} from "../../Common/FormInput"
import FacilityService from './FacilityService'
import Cs from "../../../services/CommonService"
import FormHelper from "../../FormBuilder/FormHelpers"
import DataSourceService from '../../../services/DataSourceService'

const useMedicalFacilityApi = (organizationId) => {
  
  const [loading, setLoading] = useState(true)
  const [districts, setDistricts] = useState([])
  const [talukas, setTalukas] = useState([])
  const [selectedTalukas, setSelectedTalukas] = useState([])
  const [villages, setVillages] = useState([])
  const [filteredFacilities, setfilteredFacilities] = useState([])
  let [filter, setFilter] = useState({})

  useEffect(() => {
    getDistricts()
  }, [])

  const getDistricts = async () =>{
    setLoading(true)
    FacilityService.getLocations({
      organization_id:organizationId,
      fields:['district'],
      is_distinct:true
    }).then((res)=>{
      setDistricts(res.data.list)  
      setLoading(false)
    }) 
  }

  const getTalukas = (name, value) =>{
    setLoading(true)
    setFilter({...filter, [name]:value})
    FacilityService.getLocations({
      organization_id:organizationId,
      fields:['taluka'],
      is_distinct:true,
      district:value
    }).then((res)=>{
      setTalukas(res.data.list)  
      setLoading(false)
    }) 
  }

  const getMedicalFacilities = (name, value) =>{
    setLoading(true)
    setFilter({...filter, [name]:value})

    if(value?.length <= 0){
      setfilteredFacilities([])  
      setLoading(false)
      return
    }

    const req = {
      page:1,
      per_page:100,
      organization_id:organizationId,
      fields:['id', 'phc_name'],
      district:filter.district,
      [name]:value
    }
    FacilityService.getLocations(req).then((res)=>{
      setfilteredFacilities(res.data.list)  
      setLoading(false)
    })  
  }

  const onTalukaSelect = (ids) =>{
   
  }

  const onFacilitySelect = (facility, facilityType, medicalFacilityId) => {
    
  }

  return {
    districts,
    talukas,
    getTalukas,
    villages,
    getMedicalFacilities,
    filteredFacilities
  }
}

const useMedicalFacilityAutofillHook = ({organizationId, filter, setLoading}) =>{
  const [talukas, setTalukas] = useState([]);
  const [phcTypes, setPhcTypes] = useState([]);
  const [dataSource, setDataSource] = useState({});
  const [facilities, setFacilities] = useState([]);
  const [villages, setVillages] = useState([]);

  const getFilters = async () =>{
    setLoading(true);
    const req = {
      'organization_id':organizationId
    }
    Promise.all([
      DataSourceService.getDataSource({'data_source_params':{'data_source_id':[4610]}})
    ]).then(function(responses){
      setDataSource(responses[0]);
      setLoading(false);
    }).catch(function(err){
      console.log(err);
    })
  }

  const getTaluka = async ()=>{
    if(!filter.department) return;
    const req = {
      'organization_id':organizationId,
      'department':filter.department
    }
    const {status, data} = await FacilityService.getTalukasAutofill(req);
    if(status === 200){
      setTalukas(data.map(t => {return {"taluka":t.taluka.split('/')?.[0]}}));
    }
  }

  const getDepartmentPkId = (department) => department!=null ? dataSource.health_department_india?.options?.find(o=>o.value == department)?.pk_id : null;

  const getPhcList = async (filters={}) =>{
    setLoading(true);
    const {status, data:res} = await FacilityService.getPhcAutofill({
      'organization_id':organizationId,
      'department':filter.department, 
      ...filters 
    });
    setFacilities(res);
    setLoading(false);
  }

  const getPhcTypes = async (filters={}) =>{
    setLoading(true);
    const {status, data:res} = await FacilityService.getPhcTypesAutofill({
      'organization_id':organizationId,
      'department_dsl_id':getDepartmentPkId(filter.department)
    })
    setPhcTypes(res);
    setLoading(false);
  }

  const getVillages = async (filters={}) =>{
    setLoading(true);
    const {status, data:res} = await FacilityService.getVillagesAutofill({
      'organization_id': organizationId,
      'department': filter.department,
      ...filters 
    });
    setVillages(res.map(t => {return {"village":t.village.split('/')?.[0]}}));
    setLoading(false);
  }

  return{
    getFilters,
    getTaluka,
    getDepartmentPkId,
    getPhcList,
    getPhcTypes,
    getVillages,
    talukas, setTalukas,
    phcTypes, setPhcTypes,
    dataSource, setDataSource,
    facilities, setFacilities,
    villages, setVillages
  }
}

export { useMedicalFacilityApi, useMedicalFacilityAutofillHook };