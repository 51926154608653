import React, { Fragment, useState, useEffect, useContext, useMemo} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import { CacheContext } from "../../contexts/Cache/CacheStateProvider"
import AuthenticationService from "../../services/AuthService"
import NotifiableRoleService from "./NotifiableRoleService"
import useStyle from '../../hooks/useStyle'
import ReactDOM from 'react-dom'
import Cs from '../../services/CommonService'
import env from "../../env"
import { CheckBox } from "../Common/FormInput"
import { useCurrentUserHook } from "../Authentication/useUserHook"
import { FilterAccordionPanel, useFilterHook } from "../Common/AccordionPanel"

const RoleAndUserFilter = ({toggle, exists, filter}) => {
  
  const {currentUser} = useCurrentUserHook()
  const {cacheState:{roleAndUserFilters}, cacheDispatch } = useContext(CacheContext)
  const {roles=[], orgMembers=[], isLoaded=false} = roleAndUserFilters || {}
  
  const [loading, setLoading] = useState(false)

  const {getCount} = useFilterHook({filter});

  useEffect(() => {  
    if(!isLoaded)
      getRoleAndUsers()
  }, [])

  const getRoleAndUsers = () =>{
    setLoading(true)
    const req = {
      role_params:{
        default_fields:'ignore',
        fields:['id', 'role'],
        roleable_type: 'infant_journey',
        organization_id: currentUser.current_organization_id,
      },
      organization_member_params:{
        default_fields:'ignore',
        fields:['id', 'email', 'user_id'],
        organization_id: currentUser.current_organization_id,
      }
    }
    NotifiableRoleService.getAll(req).then(({status, data})=>{
      if(status === 200){
        cacheDispatch({
          type: "UPDATE",
          payload: {
            roleAndUserFilters:{
              roles: data.roles, 
              orgMembers: data.organization_members,
              isLoaded:true
            }
          }
        })
        setLoading(false)
      }
    })
  }

  return (
    <>
      <div className="bg-white m-b-10">
        <FilterAccordionPanel
          title="Roles"
          count={getCount('role_id')}
          isOpen={filter?.role_id}>
          <div className="mxh-250 scroll-auto">
            <CheckBox list={roles || []}
              labelKey="role" valueKey="id"
              toggle={toggle} exists={exists}
              name="role_id" />
          </div>
        </FilterAccordionPanel>
      </div>

      <div className="bg-white m-b-10">
        <FilterAccordionPanel
          title="Org Members"
          count={getCount('user_id')}
          isOpen={filter?.user_id}>
          <div className="mxh-250 scroll-auto">
            <CheckBox list={orgMembers || []}
              labelKey="email" valueKey="user_id"
              toggle={toggle} exists={exists}
              name="user_id"/>
          </div>
        </FilterAccordionPanel>
      </div>
    </>
  )

}

export default RoleAndUserFilter;